import { tradesActions as actionTypes, accountActions, bwicDateFilterOptions } from '../constants';
import { RequestState } from '../constants/request-state';

const filter = {
    reported: false,
    notReported: false,
    selectedDateOption: bwicDateFilterOptions.unspecified,
    customDateRange: {},
    confirmed: false,
    pending: false,
    rejected: false,
    allocationStatus: {
        unsettled: false,
        pendingSettlement: false,
        pendingAllocation: false,
        settled: false
    }
};

const initialState = {
    filter: { ...filter, selectedDateOption: bwicDateFilterOptions.todayAndUpcoming },
    initialFilter: { ...filter },
    lastAppliedFilter: { ...filter, selectedDateOption: bwicDateFilterOptions.todayAndUpcoming },
    filterChanged: false,
    filterApplied: false,
    searchRequestState: RequestState.none,
    trades: [],
    totalRecordNumber: 0,
    page: 1,
    isRequestingExportTrades: false,
};

export const trades = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TRADES_RESET:
            return {
                ...initialState,
                lastAppliedFilter: state.lastAppliedFilter,
                filter: state.lastAppliedFilter,
            };
        case actionTypes.TRADES_STORE_TRADES:
            return {
                ...state,
                trades: state.trades.concat(action.payload.trades || []),
                totalRecordNumber: action.payload.totalRecordNumber,
                page: action.payload.page,
                lastAppliedFilter: { ...state.filter },
            };
        case actionTypes.TRADES_RESET_TRADES:
            return {
                ...state,
                trades: [],
            }
        case actionTypes.TRADES_SEARCH_IN_PROGRESS:
            return {
                ...state,
                searchRequestState: action.payload.requestState
            };
        case actionTypes.TRADES_RESET_FILTER:
            return {
                ...state,
                filter: { ...state.initialFilter },
                lastAppliedFilter: { ...filter }
            };
        case actionTypes.TRADES_FILTER_REPORTED:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    reported: !state.filter.reported
                }
            };
        case actionTypes.TRADES_FILTER_NOT_REPORTED:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    notReported: !state.filter.notReported
                }
            };
        case actionTypes.FILTER_DATE_CHANGE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    selectedDateOption: action.option
                }
            };
        case actionTypes.TOGGLE_PENDING:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    pending: !state.filter.pending
                }
            }
        case actionTypes.TOGGLE_CONFIRMED:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    confirmed: !state.filter.confirmed
                }
            }
        case actionTypes.TOGGLE_REJECTED:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    rejected: !state.filter.rejected
                }
            }
        case actionTypes.TOGGLE_UNSETTLED:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    allocationStatus: {
                        ...state.filter.allocationStatus,
                        unsettled: !state.filter.allocationStatus.unsettled
                    }
                }
            }
        case actionTypes.TOGGLE_PENDING_ALLOCATION:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    allocationStatus: {
                        ...state.filter.allocationStatus,
                        pendingAllocation: !state.filter.allocationStatus.pendingAllocation
                    }
                }
            }
        case actionTypes.TOOGLE_PENDING_PROCEEDS:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    allocationStatus: {
                        ...state.filter.allocationStatus,
                        pendingProceeds: !state.filter.allocationStatus.pendingProceeds
                    }
                }
            }
        case actionTypes.TOGGLE_PENDING_SETTLEMENT:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    allocationStatus: {
                        ...state.filter.allocationStatus,
                        pendingSettlement: !state.filter.allocationStatus.pendingSettlement
                    }
                }
            }
        case actionTypes.TOGGLE_SETTLED:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    allocationStatus: {
                        ...state.filter.allocationStatus,
                        settled: !state.filter.allocationStatus.settled
                    }
                }
            }
        case actionTypes.FILTER_CUSTOM_DATE_CHANGE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    customDateRange: action.dateRange
                }
            };
        case actionTypes.TRADES_REPORTED_FLAG:
            return {
                ...state,
                trades: state.trades.map(t => t.id === action.tradeId
                    ? { ...t, isReported: action.isReported }
                    : t)
            };
        case actionTypes.TRADES_REPORTED_REQUEST_STATE:
            return {
                ...state,
                trades: state.trades.map(t => t.id === action.tradeId
                    ? { ...t, reportedInProgress: action.inProgress }
                    : t)
            };
        case actionTypes.TRADES_SET_IS_REQUESTING_EXPORT_TRADES:
            return {
                ...state,
                isRequestingExportTrades: action.payload.status
            };
        case accountActions.LOGOUT:
            return initialState;
        default: return state;
    }
};
