import { ActionType, getType } from 'typesafe-actions';
import { PushNotificationServiceStatus } from '../constants';
import { NotificationType } from '../types/notifications/NotificationType';
import { notificationActions } from '../actions/notification.actions';
import { NotificationState } from '../types/state/NotificationState';
import { RequestState } from '../constants/request-state';

const initialState: NotificationState = {
    connectionStatus: PushNotificationServiceStatus.none,
    notificationList: [],
    notificationAttributes: {},
    modalNotificationList: [],
    hasMoreNotifications: true,
    requestStateGetNotificationList: RequestState.none,
    countUnreadNotifications: 0,
    requestStateGetCountUnread: RequestState.none,
    requestStateReadAll: RequestState.none,
};

export const notification = (
    state = initialState, 
    action: ActionType<typeof notificationActions[keyof typeof notificationActions]>): NotificationState => {
    switch (action.type) {
        case getType(notificationActions.notificationReset):
            return { ...initialState };
        case getType(notificationActions.notificationRead):
            return {
                ...state,
                notificationList: state.notificationList.map(n => action.payload.referenceName === n.referenceName
                    ? { ...n, isReadByUser: true }
                    : n
                ),
                countUnreadNotifications: state.countUnreadNotifications - 1
            };

        case getType(notificationActions.notificationReadAll):
            return {
                ...state,
                notificationList: state.notificationList.map(n => ({ ...n, isReadByUser: true })),
                countUnreadNotifications: 0,
            };

        case getType(notificationActions.notificationPushReceived):
        case getType(notificationActions.notificationAddInfo):
        case getType(notificationActions.notificationAddInfoMessage):
        case getType(notificationActions.notificationAddError):
        case getType(notificationActions.notificationAddErrorMessage):
            return {
                ...state,
                notificationList: [
                    action.payload.notification,
                    ...state.notificationList,
                ],
                notificationAttributes: {
                    ...state.notificationAttributes,
                    [action.payload.notification.referenceName]: {
                        type: action.payload.type,
                        toastVisible: true,
                        displayInNotificationList: action.payload.type === NotificationType.PushNotification,
                    }
                },
                countUnreadNotifications: action.payload.type === NotificationType.PushNotification
                    ? state.countUnreadNotifications + 1
                    : state.countUnreadNotifications
            };
        case getType(notificationActions.notificationAddErrorMessageModal):
        case getType(notificationActions.infoModal):
        case getType(notificationActions.infoModalReplace):
        case getType(notificationActions.warningModal):
            return {
                ...state,
                modalNotificationList: (action.payload.notification.modalKey
                    ? state.modalNotificationList.filter(n => n.modalKey !== action.payload.notification.modalKey)
                    : state.modalNotificationList).concat(action.payload.notification)
            };
        case getType(notificationActions.notificationListHideToast): {
            const { referenceName } = action.payload;
            return {
                ...state,
                notificationAttributes: {
                    ...state.notificationAttributes,
                    [referenceName]: {
                        ...state.notificationAttributes[referenceName],
                        toastVisible: false
                    }
                }
            };
        }
        case getType(notificationActions.notificationListRemoveModalItem):
            return {
                ...state,
                modalNotificationList: state.modalNotificationList.filter((value, index) => index !== action.payload.index)
            };

        case getType(notificationActions.notificationListToastExpired): {
            const { referenceName } = action.payload;
            return state.notificationAttributes[referenceName]
                ? {
                    ...state,
                    notificationAttributes: {
                        ...state.notificationAttributes,
                        [referenceName]: {
                            ...state.notificationAttributes[referenceName],
                            toastVisible: false
                        }
                    }
                }
                : state;
        }
        case getType(notificationActions.modalNotificationListReset):
            return {
                ...state,
                modalNotificationList: []
            };
        case getType(notificationActions.notificationServiceConnected):
            return {
                ...state,
                connectionStatus: PushNotificationServiceStatus.connected,
            };
        case getType(notificationActions.notificationServiceReconnecting):
            return {
                ...state,
                connectionStatus: PushNotificationServiceStatus.reconnecting,
            };
        case getType(notificationActions.notificationServiceReconnected):
            return {
                ...state,
                connectionStatus: PushNotificationServiceStatus.reconnected,
            };
        case getType(notificationActions.notificationServiceDisconnected):
            return {
                ...state,
                connectionStatus: PushNotificationServiceStatus.disconnected,
            };
        case getType(notificationActions.notificationGetList):
            return {
                ...state,
                requestStateGetNotificationList: RequestState.request
            };
        case getType(notificationActions.appendNotifications):
            return {
                ...state,
                notificationList: state.notificationList.concat(action.payload.notificationList),
                notificationAttributes: {
                    ...state.notificationAttributes,
                    ...action.payload.notificationAttributes,
                },
                hasMoreNotifications: !!action.payload.notificationList.length,
                requestStateGetNotificationList: RequestState.success
            };
        case getType(notificationActions.notificationGetListFailure):
            return {
                ...state,
                requestStateGetNotificationList: RequestState.failure
            };
        case getType(notificationActions.notificationsGetCountUnread):
            return {
                ...state,
                requestStateGetCountUnread: RequestState.request
            };
        case getType(notificationActions.notificationsGetCountUnreadFailure):
            return {
                ...state,
                requestStateGetCountUnread: RequestState.failure
            };
        case getType(notificationActions.notificationsSetCountUnread):
            return {
                ...state,
                countUnreadNotifications: action.payload.count,
                requestStateGetCountUnread: RequestState.success
            };
        case getType(notificationActions.notificationSetReadSuccess): {
            return {
                ...state,
                notificationList: state.notificationList.map(n => n.referenceName === action.payload.referenceName
                    ? { ...n, isReadByUser: true }
                    : n
                ),
            };
        }
        case getType(notificationActions.notificationsReadAllRequest):
            return {
                ...state,
                requestStateReadAll: RequestState.request
            };
        case getType(notificationActions.notificationsReadAllSuccess):
            return {
                ...state,
                requestStateReadAll: RequestState.success,
                notificationList: state.notificationList.map(n => ({ ...n, isReadByUser: true })),
                countUnreadNotifications: 0
            };
        case getType(notificationActions.notificationsReadAllFailure):
            return {
                ...state,
                requestStateReadAll: RequestState.failure,
            };
        default:
            return state;
    }
};
