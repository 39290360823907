import { getType } from 'typesafe-actions';
import { onBoardingTooltipConst, accountActions } from '../constants';
import { jsonUtils } from '../utils/json.utils';
import { RequestState } from '../constants/request-state';
import {
    onBoardingTooltipNav,
    onBoardingTooltipClose,
    onBoardingTooltipGotIt,
    onBoardingTooltipSetVisible,
    onBoardingTooltipSetCountRequest,
    onBoardingTooltipSetCountSuccess,
    onBoardingTooltipSetCountFailure,
    onBoardingTooltipSetVisibleSupportPopup,
    onBoardingReset
} from '../actions';

const initialState = {
    pages: {
        [onBoardingTooltipConst.pages.allBwics]: {
            current: 1,
            closed: false,
            gotIt: false,
        },
        [onBoardingTooltipConst.pages.portfolio]: {
            current: 1,
            closed: false,
            gotIt: false,
        },
        [onBoardingTooltipConst.pages.bwicOpenBidding]: {
            current: 1,
            closed: false,
            gotIt: false,
        }
    },
    requestStateSetCont: RequestState.none,
    visibleSupportPopup: false,
};

function getOnBoardingTooltipStateFromStorage() {
    const data = localStorage.getItem(onBoardingTooltipConst.localStorageKey);
    const state = jsonUtils.tryParse(data);
    if (state && typeof state === 'object' && state.pages) {
        for (let key in onBoardingTooltipConst.pages) {
            const statePageKey = onBoardingTooltipConst.pages[key];
            if (!state.pages[statePageKey]) {
                return { ...initialState };
            }
            state.pages[statePageKey].closed = false
        }
        state.visibleSupportPopup = false;
        return { ...state };
    }
    return { ...initialState };
}


function setVisible(state, status) {
    if (status) {
        return { ...initialState.pages }
    }
    const newState = JSON.parse(JSON.stringify(state));
    for (let key in onBoardingTooltipConst.pages) {
        const pageKey = onBoardingTooltipConst.pages[key];
        newState[pageKey].closed = true;
        newState[pageKey].gotIt = true;
        newState[pageKey].current = 1;
    }
    return newState
}

function setVisibleFromUser(state, userState) {
    const newState = JSON.parse(JSON.stringify(state));
    if (userState && userState.tooltipCounts && typeof userState.tooltipCounts === 'object') {
        for (let key in onBoardingTooltipConst.pages) {
            const pageKey = onBoardingTooltipConst.pages[key];
            if (
                typeof userState.tooltipCounts[pageKey] === 'number' &&
                userState.tooltipCounts[pageKey] >= onBoardingTooltipConst.maxCountLimit
            ) {
                newState.pages[pageKey].gotIt = true;
            }
        }
    }
    return newState;
}

export function onBoardingTooltip(state = getOnBoardingTooltipStateFromStorage(), action) {
    switch (action.type) {
        case accountActions.LOGIN_SUCCESS:
            return setVisibleFromUser(state, action.user);

        case getType(onBoardingTooltipNav):
            let { current } = state.pages[action.payload.page];
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.payload.page]: {
                        ...state.pages[action.payload.page],
                        current: action.payload.next ? ++current : --current
                    }
                }
            };
        case getType(onBoardingTooltipClose):
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.payload.page]: {
                        ...state.pages[action.payload.page],
                        closed: true
                    }
                },
                visibleSupportPopup: true,
            };
        case getType(onBoardingTooltipGotIt):
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.payload.page]: {
                        ...state.pages[action.payload.page],
                        gotIt: true
                    }
                },
                visibleSupportPopup: !action.payload.doNotShowSupportPopup
            };

        case getType(onBoardingTooltipSetVisible):
            return {
                ...state,
                pages: setVisible(state.pages, action.payload.status)
            };

        case getType(onBoardingTooltipSetCountRequest):
            return {
                ...state,
                requestStateSetCont: RequestState.request
            };
        case getType(onBoardingTooltipSetCountSuccess):
            return {
                ...state,
                requestStateSetCont: RequestState.success
            };
        case getType(onBoardingTooltipSetCountFailure):
            return {
                ...state,
                requestStateSetCont: RequestState.failure
            };
        case getType(onBoardingTooltipSetVisibleSupportPopup):
            return {
                ...state,
                visibleSupportPopup: action.payload.status
            };
        case getType(onBoardingReset):
            return {
                ...initialState
            };

        default:
            return state;
    }
}
