import { combineReducers } from 'redux';
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { amrPipelineCommon } from './amr-common.reducer';
import { amrPipelineDetailed } from './amr-pipeline-detailed.reducer';
import { amrPipelineHistory } from './amr-pipeline-history.reducer';
import { createAmrPipelineWithNamedData } from './amr-pipeline.reducer';

export const issuanceMonitor = combineReducers({
    amrPipeline: createAmrPipelineWithNamedData(PipelineType.Deals),
    amrPipelineIOIs: createAmrPipelineWithNamedData(PipelineType.IOIs),
    amrArrangerPipeline: createAmrPipelineWithNamedData(PipelineType.ArrangerPipeline),
    amrPipelineHistory,
    amrPipelineDetailed,
    amrPipelineCommon,
});
