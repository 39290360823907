import { ActionType } from '../types/ActionType';
import { ReleasesState } from '../types/state/ReleasesState';
import { RequestState } from '../constants/request-state';
import { releaseActions } from '../actions/release.actions';
import { getType } from 'typesafe-actions';

const initialReleasesState: ReleasesState = {
    data: {
        listStartSymbol: '',
        listEndSymbol: '',
        listItemStartSymbol: '',
        listItemEndSymbol: '',
        releases: []
    },
    images: {},
    requestImageStatus: {},
    requestState: RequestState.none,
    requestStatusMarkAsRead: false
};

export const release = (state = initialReleasesState, action: ActionType<any>) => {
    switch (action.type) {
        case getType(releaseActions.getReleaseRequest):
            return {
                ...state,
                requestState: RequestState.request
            };
        case getType(releaseActions.getReleaseResponse):
            return {
                ...state,
                requestState: action.payload.requestStatus,
                data: action.payload.releases
            };
        case getType(releaseActions.markAsReadRequest):
            return {
                ...state,
                requestStatusMarkAsRead: true
            };
        case getType(releaseActions.markAsReadResponse):
            return {
                ...state,
                requestStatusMarkAsRead: false
            };
        default:
            return state;
    }
};
