import { getType } from 'typesafe-actions';
import { NewsState } from '../types/state/NewsState';
import { newsActions, TNewsActions } from '../actions/news.actions';
import { NewsCategory } from '../types/news/enums/NewsCategory';
import { NewsTabCategory } from '../types/news/enums/NewsTabCategory';

const initialState: NewsState = {
    isMarketingEventsLoading: true,
    isNewsLoading: true,
    events: [],
    news: {
        [NewsTabCategory.All]: new Map(),
        [NewsTabCategory.Primary]: new Map(),
        [NewsTabCategory.Secondary]: new Map(),
        [NewsTabCategory.Research]: new Map(),
    },
    newsOffset: 0,
    totalNews: {
        [NewsTabCategory.All]: 0,
        [NewsCategory.Primary]: 0,
        [NewsCategory.Secondary]: 0,
        [NewsCategory.Research]: 0,
    },
};

export const news = (state = initialState, action: TNewsActions): NewsState => {
    switch (action.type) {
        case getType(newsActions.fetchMarketingEventsResponse):
            return {
                ...state,
                isMarketingEventsLoading: false,
                events: action.payload.events,
            };
        case getType(newsActions.fetchNewsRequest):
        case getType(newsActions.fetchTotalNewsCountRequest):
            return {
                ...state,
                isNewsLoading: true,
            };
        case getType(newsActions.fetchNewsResponse):
            return {
                ...state,
                isNewsLoading: false,
                news: {
                    ...state.news,
                    [action.payload.tab]: new Map(state.news[action.payload.tab]).set(
                        action.payload.page,
                        action.payload.news,
                    ),
                },
            };
        case getType(newsActions.fetchTotalNewsCountResponse):
            return {
                ...state,
                totalNews: action.payload.totalNews,
            };
        case getType(newsActions.fetchNewsDetails):
            return {
                ...state,
                isNewsLoading: true,
            };
        case getType(newsActions.fetchNewsDetailsResponse):
            return {
                ...state,
                isNewsLoading: false,
                currentNews: action.payload.news,
            };
        case getType(newsActions.reset):
            return initialState;
        case getType(newsActions.resetNewsList):
            return {
                ...state,
                news: initialState.news,
                totalNews: initialState.totalNews,
            };
        default:
            return state;
    }
}
