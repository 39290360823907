import { getType } from "typesafe-actions";
import { signupActions, TSignupAction } from "../actions/signup.actions";
import { RequestState } from '../constants/request-state';

const initialState = {
    requestState: RequestState.none
}

export function signup(state = initialState, action: TSignupAction) {
    switch (action.type) {
        case getType(signupActions.submit):
            return {
                requestState: RequestState.request,
            };
        case getType(signupActions.submitSuccess):
            return {
                requestState: RequestState.success,
            };
        case getType(signupActions.submitError):
            return {
                requestState: RequestState.failure,
            };
        default: return state;
    }
}
