import { otherSellerBiddingActions as actionTypes, pushDataActions, biddingActions } from '../constants';
import { biddingPushDataReducer } from './bidding.push.data.reducer';

const initialState = {
    securities: []
};

export const otherSellerBidding = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OTHER_SELLER_BIDDING_STORE_SECURITIES:
            return {
                ...state,
                securities: [...action.securities]
            };
        case pushDataActions.PUSH_DATA_NEW_PX_TALK:
            return biddingPushDataReducer(state, action);
        case actionTypes.OTHER_SELLER_BIDDING_RESET:
        case biddingActions.BIDDING_RESET:
            return { ...initialState };
        default:
            return state;
    }
}
