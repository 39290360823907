import { AnyAction } from "redux"
import { getType } from 'typesafe-actions';
import { inventoryActions } from '../actions/inventory.actions';
import { RequestState } from '../constants/request-state';

interface InventoryState {
    isInventoryExist: boolean;
    requestStateInventoryExist: RequestState;
    requestStatusExportInventory: boolean;
    advancedFilterBlocked: boolean;
}

const initialInventoryState = {
    isInventoryExist: false,
    requestStateInventoryExist: RequestState.none,
    requestStatusExportInventory: false,
    advancedFilterBlocked: false,
};

export const inventory = (state = initialInventoryState, action: AnyAction): InventoryState => {
    switch (action.type) {
        case getType(inventoryActions.reset):
            return { ...initialInventoryState };
        case getType(inventoryActions.requestInventoryExistFlag):
            return {
                ...state,
                requestStateInventoryExist: RequestState.request
            };
        case getType(inventoryActions.inventoryExistFlag):
            return {
                ...state,
                requestStateInventoryExist: RequestState.success,
                isInventoryExist: action.payload.exist
            };
        case getType(inventoryActions.exportInventoryRequest):
            return {
                ...state,
                requestStatusExportInventory: true
            };
        case getType(inventoryActions.exportInventoryResponse):
            return {
                ...state,
                requestStatusExportInventory: false
            };
        case getType(inventoryActions.advancedFilterBlocked):
            return {
                ...state,
                advancedFilterBlocked: action.payload.blocked
            }
        default:
            return state
    }
};
