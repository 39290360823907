import { ActionType, getType } from 'typesafe-actions';
import { SellerBuysideFilterState, SellerBuysideState } from '../types/state/SellerBuysideState';
import { BwicStatus } from '../types/enums/BwicStatus';
import { bwicDateFilterOptions } from '../constants';
import { Ratings } from '../types/enums/Rating';
import { Currencies } from '../types/enums/Currency';
import { pushBwicActions, sellerBuysideActions, TPositionColor } from '../actions';
import { SellerBuysideSearchResult } from '../types/bid-as-dealer/SellerBuysideSearchResult';
import { RequestState } from '../constants/request-state';
import { BwicTypes } from '../types/enums/BwicType';
import { EditBidRequestState } from '../types/state/SellerBuysideState';
import { formatUtils, isRequestSuccess } from '../utils';
import { BidRequestStatus } from '../types/bid-as-dealer/BidRequestStatus';
import { user } from '../user';
import { OpenBiddingStatus } from '../types/enums/OpenBiddingStatus';
import { SettlementAgentAgreement } from '../types/bid-as-dealer/SettlementAgentAgreement';
import { BwicMonitorSortByOptions } from '../types/state/AllBwicsState';

const initialFilter: SellerBuysideFilterState = {
    bwicStatuses: [
        { value: BwicStatus.scheduled, text: 'Scheduled', selected: false, visible: true, disabled: false },
        { value: BwicStatus.bidding, text: 'Bidding', selected: false, visible: true, disabled: false },
        { value: BwicStatus.finished, text: 'Finished', selected: false, visible: true, disabled: false },
        { value: BwicStatus.canceled, text: 'Canceled', selected: false, visible: true, disabled: false },
    ],
    ratings: Ratings.map(r => ({
        value: r.toString(), text: r.toString(), selected: false, visible: true, disabled: false
    })),
    currencies: Currencies.map(c => ({
        value: c.toString(), text: c.toString(), selected: false, visible: true, disabled: false
    })),
    types: BwicTypes.map(t => ({
        value: t.toString(), text: t.toString(), selected: false, visible: true, disabled: false
    })),
    size: { min: '', max: '' },
    selectedDateOption: bwicDateFilterOptions.unspecified,
    customDateRange: {},
    submittedBids: null
};

const initialState: SellerBuysideState = {
    editBidRequestState: {},
    initialFilter: { ...initialFilter },
    initRequestStatus: RequestState.none,
    filter: { ...initialFilter, selectedDateOption: bwicDateFilterOptions.todayAndUpcoming },
    lastAppliedFilter: undefined,
    isLoading: false,
    dataItems: [],
    bidAsDealerRequestExist: false,
    currentPageNumber: 1,
    pageSize: 50,
    totalRecordNumber: 0,
    requestStateSubmitBidRequest: {},
    searchTermItems: [],
    requestStateExportBidRequests: RequestState.none,
    sortBy: BwicMonitorSortByOptions.NewestFirst,
    advancedFiltersBlocked: false,
    summary: undefined
};

type TSellerBuysideAction = ActionType<typeof sellerBuysideActions[keyof typeof sellerBuysideActions]>;
type TPushBwicActions = ActionType<typeof pushBwicActions[keyof typeof pushBwicActions]>;

export function sellerBuyside(state: SellerBuysideState = initialState, action: TSellerBuysideAction | TPushBwicActions): SellerBuysideState {
    switch (action.type) {
        case getType(sellerBuysideActions.reset): {
            if (state.initialFilter == null || !state.lastAppliedFilter) {
                return { ...initialState };
            }
            return {
                ...initialState,
                initialFilter: { ...state.initialFilter },
                filter: { ...state.lastAppliedFilter }
            };
        }
        case getType(sellerBuysideActions.hardReset):
            return { ...initialState };
        case getType(sellerBuysideActions.initRequestStatus):
            return {
                ...state,
                initRequestStatus: action.payload.requestStatus,
                bidAsDealerRequestExist: action.payload.bidAsDealerRequestExist
            };
        case getType(sellerBuysideActions.dateFilterChange):
            return {
                ...state,
                filter: {
                    ...state.filter,
                    selectedDateOption: action.payload.dateOption,
                    customDateRange: action.payload.dateOption.key === bwicDateFilterOptions.custom.key ?
                        state.filter.customDateRange : {}
                }
            };
        case getType(sellerBuysideActions.customDateFilterChange):
            return {
                ...state,
                filter: {
                    ...state.filter,
                    selectedDateOption: bwicDateFilterOptions.custom,
                    customDateRange: action.payload.dateRange
                }
            };
        case getType(sellerBuysideActions.bwicStatusFilterChange):
            return {
                ...state,
                filter: {
                    ...state.filter,
                    bwicStatuses: state.filter.bwicStatuses.map(s =>
                        s.value === action.payload.status
                            ? { ...s, selected: action.payload.selected }
                            : s)
                }
            };
        case getType(sellerBuysideActions.currencyFilterChange):
            return {
                ...state,
                filter: {
                    ...state.filter,
                    currencies: state.filter.currencies.map(c =>
                        c.value === action.payload.currency
                            ? { ...c, selected: action.payload.selected }
                            : c)
                }
            };
        case getType(sellerBuysideActions.ratingFilterChange):
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ratings: state.filter.ratings.map(r =>
                        r.value === action.payload.rating
                            ? { ...r, selected: action.payload.selected }
                            : r)
                }
            };
        case getType(sellerBuysideActions.typeFilterChange):
            return {
                ...state,
                filter: {
                    ...state.filter,
                    types: state.filter.types.map(s =>
                        s.value === action.payload.type
                            ? { ...s, selected: action.payload.selected }
                            : s)
                }
            };
        case getType(sellerBuysideActions.sizeFilterChange):
            return {
                ...state,
                filter: {
                    ...state.filter,
                    size: action.payload.size
                }
            }
        case getType(sellerBuysideActions.submittedBidsFilterChange):
            return {
                ...state,
                filter: {
                    ...state.filter,
                    submittedBids: action.payload.submittedBidsFilterValue
                }
            };
        case getType(sellerBuysideActions.dataItemsLoadingState):
            return {
                ...state,
                isLoading: action.payload.isLoading
            };
        case getType(sellerBuysideActions.storeSearchResult): {
            const dataItems = state.currentPageNumber === 1
                ? action.payload.result.result
                : state.dataItems.concat(action.payload.result.result);
            return {
                ...state,
                dataItems,
                totalRecordNumber: action.payload.result.totalRecordNumber,
                summary: action.payload.summary ?? state.summary
            };
        }
        case getType(sellerBuysideActions.storeCurrencySelectedStatus):
            return {
                ...state,
                filter: {
                    ...state.filter,
                    currencies: state.filter.currencies.map(c => ({ ...c, selected: action.payload.isSelected }))
                }
            };
        case getType(sellerBuysideActions.loadNextPage):
            return {
                ...state,
                currentPageNumber: state.currentPageNumber + 1
            };
        case getType(sellerBuysideActions.pagingReset):
            return {
                ...state,
                currentPageNumber: 1,
                totalRecordNumber: 0
            };
        case getType(sellerBuysideActions.filterReset):
            return {
                ...state,
                filter: { ...initialFilter },
                lastAppliedFilter: { ...initialFilter }
            };
        case getType(sellerBuysideActions.updateRequestStateSubmitBid):
            return {
                ...state,
                requestStateSubmitBidRequest: {
                    ...state.requestStateSubmitBidRequest,
                    [action.payload.positionId]: action.payload.status
                }
            };
        case getType(sellerBuysideActions.updateBidRequests): {
            return {
                ...state,
                dataItems: state.dataItems.map(i =>
                    action.payload.dataItems.find(d =>
                        d.bidAsDealerRequest && d.bidAsDealerRequest?.id === i.bidAsDealerRequest?.id
                    ) || i
                )
            };
        }
        case getType(sellerBuysideActions.storeAppliedFilter):
            return {
                ...state,
                lastAppliedFilter: { ...action.payload.filter }
            };
        case getType(sellerBuysideActions.saveSearchTermItems):
            return {
                ...state,
                searchTermItems: action.payload.searchItems
            };
        case getType(sellerBuysideActions.confirmBidRequestPopup): {
            const positionId: number = action.payload.positionId;
            return {
                ...state,
                editBidRequestState: {
                    ...state.editBidRequestState,
                    [positionId]: {
                        ...state.editBidRequestState[positionId],
                        isConfirming: action.payload.visible
                    }
                }
            };
        }
        case getType(sellerBuysideActions.updateEditBidRequestState):
            return { ...state, editBidRequestState: action.payload.editBidRequestState };
        case getType(sellerBuysideActions.exportBidRequestsRequest):
            return { ...state, requestStateExportBidRequests: RequestState.request };
        case getType(sellerBuysideActions.exportBidRequestsSuccess):
            return { ...state, requestStateExportBidRequests: RequestState.success };
        case getType(sellerBuysideActions.exportBidRequestsFailure):
            return { ...state, requestStateExportBidRequests: RequestState.failure };
        case getType(sellerBuysideActions.levelChange): {
            const editState = getEditStateOrDefault(action.payload.positionId, state.editBidRequestState, state.dataItems, action.payload.agreement);
            return {
                ...state,
                editBidRequestState: {
                    ...state.editBidRequestState,
                    [action.payload.positionId]: {
                        ...editState,
                        level: {
                            ...editState.level,
                            value: action.payload.value,
                            valueRaw: action.payload.valueRaw,
                            error: action.payload.error
                        }
                    }
                }
            };
        }
        case getType(sellerBuysideActions.axedChange):
            return {
                ...state,
                editBidRequestState: {
                    ...state.editBidRequestState,
                    [action.payload.positionId]: {
                        ...getEditStateOrDefault(action.payload.positionId, state.editBidRequestState, state.dataItems, action.payload.agreement),
                        axed: action.payload.axed
                    }
                }
            };
        case getType(sellerBuysideActions.finalChange):
            return {
                ...state,
                editBidRequestState: {
                    ...state.editBidRequestState,
                    [action.payload.positionId]: {
                        ...getEditStateOrDefault(action.payload.positionId, state.editBidRequestState, state.dataItems, action.payload.agreement),
                        final: action.payload.final
                    }
                }
            };
        case getType(sellerBuysideActions.commissionChange):
            return {
                ...state,
                editBidRequestState: {
                    ...state.editBidRequestState,
                    [action.payload.positionId]: {
                        ...getEditStateOrDefault(action.payload.positionId, state.editBidRequestState, state.dataItems, action.payload.agreement),
                        commission: { value: action.payload.commission, error: action.payload.error },
                    }
                }
            }
        case getType(sellerBuysideActions.resetPositionEditState):
            return {
                ...state,
                editBidRequestState: resetEditStateReducer(state.editBidRequestState, undefined, action.payload.positionId)
            };
        case getType(sellerBuysideActions.resetSummary):
            return {
                ...state,
                summary: undefined
            };
        // Push data
        case getType(pushBwicActions.quickFeedback): {
            if (state.dataItems.some(i => i.bwic.referenceName === action.bwicReferenceName && i.position.id === action.positionId)) {
                return {
                    ...state,
                    dataItems: state.dataItems.map(i =>
                        i.bwic.referenceName === action.bwicReferenceName &&
                            i.position.id === action.positionId &&
                            i.bidAsDealerRequest?.directBid != null &&
                            i.bidAsDealerRequest.directBid.id === action.feedback.bidId
                            ? {
                                ...i,
                                bidAsDealerRequest: {
                                    ...i.bidAsDealerRequest,
                                    directBid: {
                                        ...i.bidAsDealerRequest.directBid,
                                        feedback: action.feedback.feedback,
                                        feedbackCreatedUtc: action.feedback.feedbackCreatedUtc,
                                    }
                                }
                            }
                            : i)
                };
            }

            return state;
        }
        case getType(pushBwicActions.colorDistribution):
            return {
                ...state,
                editBidRequestState: resetEditStateReducer(state.editBidRequestState, action.bwicReferenceName),
                dataItems: state.dataItems.map(i =>
                    i.bwic.referenceName === action.bwicReferenceName
                        ? {
                            ...i,
                            bwic: { ...i.bwic, isColorDistribution: action.isColorDistribution }
                        }
                        : i
                )
            };
        case getType(pushBwicActions.tradedAway):
            return {
                ...state,
                dataItems: state.dataItems.map(i =>
                    i.bwic.referenceName === action.bwicReferenceName && i.position.id === action.positionId
                        ? {
                            ...i,
                            position: {
                                ...i.position,
                                trade: undefined,
                                isTradedAway: action.isTradedAway
                            }
                        }
                        : i
                )
            };
        case getType(pushBwicActions.tradeAction):
            return {
                ...state,
                dataItems: state.dataItems.map(i =>
                    i.bwic.referenceName === action.bwicReferenceName && i.position.id === action.positionId
                        ? {
                            ...i,
                            position: {
                                ...i.position,
                                trade: action.trade,
                                isTradedAway: false
                            }
                        }
                        : i
                )
            };
        case getType(pushBwicActions.bwicStatusChanged):
            return {
                ...state,
                editBidRequestState: action.bwicStatus === BwicStatus.bidding
                    ? state.editBidRequestState
                    : resetEditStateReducer(state.editBidRequestState, action.bwicReferenceName),
                dataItems: state.dataItems.map(i => i.bwic.referenceName === action.bwicReferenceName
                    ? { ...i, bwic: { ...i.bwic, status: action.bwicStatus } }
                    : i
                )
            };
        case getType(pushBwicActions.publicColors):
            return {
                ...state,
                dataItems: state.dataItems.map(i => i.bwic.referenceName === action.bwicReferenceName
                    ? {
                        ...i,
                        position: {
                            ...i.position,
                            color: action.colors.find((c: TPositionColor) => c.positionId === i.position.id)?.color ?? i.position.color
                        }
                    }
                    : i
                )
            };
        case getType(pushBwicActions.bidAsDealerRequest): {
            const bidAsDealerRequest = action.bidAsDealerRequest as SellerBuysideSearchResult;
            const exists = state.dataItems.some(item => item.position.id === bidAsDealerRequest.position.id);

            if (exists) {
                const isConfirming = state.editBidRequestState[bidAsDealerRequest.position.id]?.isConfirming;
                const isCurrentUserBidRequest = user.current()?.id === bidAsDealerRequest.bidAsDealerRequest?.createdBy;

                return {
                    ...state,
                    editBidRequestState: isConfirming && isCurrentUserBidRequest
                        ? state.editBidRequestState
                        : resetEditStateReducer(state.editBidRequestState, undefined, bidAsDealerRequest.position.id),
                    dataItems: state.dataItems.map(i => i.position.id === bidAsDealerRequest.position.id
                        ? bidAsDealerRequest
                        : i
                    )
                };
            }

            if (
                !state.isLoading &&
                isRequestSuccess(state.initRequestStatus) &&
                bidAsDealerRequest.bidAsDealerRequest?.status !== BidRequestStatus.expired) {
                return {
                    ...state,
                    dataItems: [bidAsDealerRequest, ...state.dataItems]
                };
            }

            return state;
        }
        case getType(pushBwicActions.axedFinalChange): {
            const { positionId, bidId, bwicReferenceName, axed, final, modifiedDate, createdBy } = action;
            const isConfirming = state.editBidRequestState[positionId]?.isConfirming;
            const isCurrentUserChange = user.current()?.id === Number(createdBy);

            return {
                ...state,
                editBidRequestState: isConfirming && isCurrentUserChange
                    ? state.editBidRequestState
                    : resetEditStateReducer(state.editBidRequestState, undefined, positionId),
                dataItems: state.dataItems.map(i =>
                    i.bwic.referenceName === bwicReferenceName &&
                        i.position.id === positionId &&
                        i.bidAsDealerRequest?.directBid?.id === bidId
                        ? { ...i, bidAsDealerRequest: { ...i.bidAsDealerRequest, axed, final, modifiedDate } }
                        : i
                )
            };
        }
        case getType(pushBwicActions.stagedBiddingStartStage2):
            if (!action.canDealerBidOnPositions?.length) return state;

            const { stage1EndDateUtc } = action;

            let editBidRequestState = state.editBidRequestState;
            action.canDealerBidOnPositions?.forEach(x => {
                if (!x.canBidOnStage2) { // Reset edit state for position if can not bid
                    editBidRequestState = resetEditStateReducer(
                        editBidRequestState,
                        action.bwicReferenceName,
                        x.positionId);
                }
            });

            return {
                ...state,
                editBidRequestState,
                dataItems: state.dataItems.map(i => {
                    const canBidOnPosition = action.canDealerBidOnPositions?.find(x => i.position.id === x.positionId)

                    return i.bwic.referenceName === action.bwicReferenceName
                        ? {
                            ...i,
                            bwic: {
                                ...i.bwic,
                                process: {
                                    ...i.bwic.process,
                                    stagedBiddingStatus: OpenBiddingStatus.stage1Ended,
                                    jumpBall: i.bwic.process.jumpBall == null
                                        ? i.bwic.process.jumpBall
                                        : { ...i.bwic.process.jumpBall, stage1EndDateUtc },
                                    topX: i.bwic.process.topX == null
                                        ? i.bwic.process.topX
                                        : { ...i.bwic.process.topX, stage1EndDateUtc },
                                    liveBidding: i.bwic.process.liveBidding == null
                                        ? i.bwic.process.liveBidding
                                        : { ...i.bwic.process.liveBidding, stage1EndDateUtc }
                                }
                            },
                            position: {
                                ...i.position,
                                isStage2Participant: Boolean(canBidOnPosition?.canBidOnStage2),
                                tiedForBest: canBidOnPosition?.tiedForBest,
                                openBiddingStage2Level: canBidOnPosition?.level
                            }
                        }
                        : i;
                })
            };
        case getType(pushBwicActions.stagedBiddingFinishStage2):
            return {
                ...state,
                editBidRequestState: resetEditStateReducer(state.editBidRequestState, action.bwicReferenceName),
                dataItems: state.dataItems.map(i => i.bwic.referenceName === action.bwicReferenceName
                    ? {
                        ...i,
                        bwic: {
                            ...i.bwic,
                            process: {
                                ...i.bwic.process,
                                stagedBiddingStatus: OpenBiddingStatus.improvementRoundEnded
                            }
                        }
                    }
                    : i
                )
            };
        case getType(pushBwicActions.liveBiddingStage2Level):
            return {
                ...state,
                dataItems: state.dataItems.map(i =>
                    i.bwic.referenceName === action.bwicReferenceName && i.position.id === action.positionId
                        ? {
                            ...i,
                            position: {
                                ...i.position,
                                openBiddingStage2Level: action.level,
                                tiedForBest: action.tiedForBest,
                                latestBidSubmission: action.sentDateUtc
                            }
                        }
                        : i
                )

            };
        case getType(sellerBuysideActions.liveBiddingStage2TimerExpired):
            return {
                ...state,
                dataItems: state.dataItems.map(i => i.position.id === action.payload.positionId
                    ? { ...i }
                    : i)
            };
        case getType(sellerBuysideActions.sortFieldChanged):
        case getType(sellerBuysideActions.sortChanged):
            return {
                ...state,
                sortBy: action.payload.sortBy
            }
        case getType(sellerBuysideActions.advancedFiltersBlocked):
            return {
                ...state,
                advancedFiltersBlocked: action.payload.blocked
            }
        default:
            return state;
    }
}

function getEditStateOrDefault(positionId: number, editState: EditBidRequestState, dataItems: SellerBuysideSearchResult[], agreement: SettlementAgentAgreement) {
    return editState[positionId] ?? getDefaultEditState(positionId, dataItems, agreement);
}

function getDefaultEditState(positionId: number, dataItems: SellerBuysideSearchResult[], agreement: SettlementAgentAgreement) {
    const item = dataItems.find(i => i.position.id === positionId);

    if (item) {
        return {
            bwicReferenceName: item.bwic.referenceName,
            positionId: item.position.id,
            positionSize: item.bidAsDealerRequest?.size ?? item.position.size,
            level: {
                valueRaw: item.bidAsDealerRequest == null
                    ? ''
                    : formatUtils.formatBid(item.bidAsDealerRequest.level),
                value: item.bidAsDealerRequest == null
                    ? undefined
                    : item.bidAsDealerRequest.level,
                error: ''
            },
            axed: item.bidAsDealerRequest?.axed ?? false,
            final: item.bidAsDealerRequest?.final ?? false,
            commission: {
                value: item.bidAsDealerRequest?.commission || agreement.commissions[item.position.rating],
                error: ''
            },
            settlementAgent: item.bidAsDealerRequest?.brokerDealer || agreement.settlementAgent,
            modifiedDate: item.bidAsDealerRequest?.modifiedDate,
            isConfirming: false
        };
    }

    return undefined;
}


function resetEditStateReducer(state: EditBidRequestState, bwicReferenceName?: string, positionId?: number) {
    const stateCopy = { ...state };

    Object.keys(state)
        .map(key => +key)
        .forEach(id => {
            const editState = stateCopy[id];
            if (
                !editState.isConfirming &&
                (
                    (bwicReferenceName && editState?.bwicReferenceName === bwicReferenceName) ||
                    (positionId && id === positionId)
                )
            ) {
                delete stateCopy[id];
            }
        });

    return stateCopy;
}
