import { cloneDeep } from 'lodash';
import {
    sellerBiddingActions as actionTypes,
    colorDistributionActions,
    pushDataActions,
    biddingActions,
    autoFeedbackSettingsActions
} from '../constants';
import { RequestState } from '../constants/request-state';
import { biddingPushDataReducer, shouldHandlePushData } from './bidding.push.data.reducer';
import { apiUtils, biddingUtils } from '../utils';
import { getSecuritiesWithLatestBids } from '../selectors';
import { isActiveTrade, TradeStatus } from '../types/trades/TradeStatus';
import { ColorDistributionType } from '../types/color-distribution/ColorDistributionType';

// See SelerBiddingState.tsx
const initialState = {
    securities: [],
    holidays: [],
    tradingConfirmationVisible: false,
    minimumSettleDate: null,
    tradingPositions: {},
    tradeAllChecked: true,
    isCompleteInProgress: false,
    colorDistribution: {
        forTradedType: ColorDistributionType.CoverHandle,
        forDNTType: ColorDistributionType.BestHandle
    },
    bidList: {
        visible: false,
        positionId: undefined
    },
    autoFeedbackSettingsVisible: false,
    autoFeedbackSettingsSaving: false,
    isAutoFeedbackBlastMessageSending: false,
    editTargetLevel: {}, // [positionId]: { targetLevel, targetLevelStatus }
};

export const sellerBidding = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELLER_BIDDING_STORE_SECURITIES:
            return {
                ...state,
                securities: action.securities
            };
        case actionTypes.SELLER_BIDDING_TARGET_LEVEL_REQUEST:
        case actionTypes.SELLER_BIDDING_TARGET_LEVEL_SUCCESS:
        case actionTypes.SELLER_BIDDING_TARGET_LEVEL_FAILURE:
        case actionTypes.SELLER_BIDDING_SET_TARGET_LEVEL:
            return {
                ...state,
                editTargetLevel: targetLevelReducer(state.editTargetLevel, action)
            }
        case actionTypes.STORE_MIN_SETTLE_DATE:
            return {
                ...state,
                minimumSettleDate: action.minimumDate
            };
        case actionTypes.SELLER_BIDDING_STORE_HOLIDAYS:
            return {
                ...state,
                holidays: action.holidays
            };
        case actionTypes.SELLER_BIDDING_QUICK_FEEDBACK_SENT: {
            return {
                ...state,
                securities: state.securities.map(s => s.id === action.positionId
                    ? {
                        ...s,
                        bids: s.bids.map(b => action.bids.some(bid => biddingUtils.getUniqueBidId(bid) === biddingUtils.getUniqueBidId(b))
                            ? { ...b, feedback: action.feedbackType }
                            : b
                        )
                    }
                    : s
                )
            };
        }
        // trade popup
        case actionTypes.SELLER_BIDDING_STORE_TRADING_POSITIONS:
            return {
                ...state,
                tradeAllChecked: true,
                tradingPositions: action.tradingPositions
                    ? apiUtils.normalize(
                        action.tradingPositions,
                        position => position.id,
                        position => ({
                            selected: true,
                            tradingCompanyId: position.tradingCompanyId,
                            tradingCompanyIdentifier: position.tradingCompanyIdentifier,
                            tradingBidId: position.tradingBidId,
                            settleDate: position.settleDate,
                            comment: '',
                            errors: null
                        }))
                    : {}
            };
        case actionTypes.SELLER_BIDDING_TRADING_CONFIRMATION_VISIBLE:
            return {
                ...state,
                tradingConfirmationVisible: action.visible
            };
        case actionTypes.SELLER_BIDING_STORE_TRADING_POSITIONS_VALIDATION_RESULT: {
            const tradingPositions = { ...state.tradingPositions };
            action.validationResult.forEach(r => {
                tradingPositions[r.positionId].errors = r.errors;
            });

            return {
                ...state,
                tradingPositions
            };
        };
        case actionTypes.SELLER_BIDDING_TRADING_POSITION_SELECTION_CHANGE:
            return {
                ...state,
                tradingPositions: {
                    ...state.tradingPositions,
                    [action.positionId]: {
                        ...state.tradingPositions[action.positionId],
                        selected: action.selected,
                        errors: null
                    }
                }
            };
        case actionTypes.SELLER_BIDDING_TRADING_TRADE_ALL_SELECTION_CHANGE:
            const tradingPositions = { ...state.tradingPositions };
            Object
                .keys(tradingPositions)
                .map(positionId => tradingPositions[+positionId].selected = action.tradeAllChecked);

            return {
                ...state,
                tradeAllChecked: action.tradeAllChecked,
                tradingPositions
            };
        case actionTypes.SELLER_BIDDING_TRADING_COMPANY_CHANGE: {
            const position = state.securities.find(s => s.id === action.positionId);
            const [{ bids }] = getSecuritiesWithLatestBids({ securities: [position] });
            const targetBid = bids.find(b => biddingUtils.getBidCompanyIdentifier(b) === String(action.tradingCompanyIdentifier));

            return {
                ...state,
                tradingPositions: {
                    ...state.tradingPositions,
                    [action.positionId]: {
                        ...state.tradingPositions[action.positionId],
                        tradingCompanyId: targetBid.company.id,
                        tradingCompanyIdentifier: action.tradingCompanyIdentifier,
                        tradingBidId: targetBid.id
                    }
                }
            };
        }
        case actionTypes.SELLER_BIDDING_TRADING_BID_CHANGE:
            return {
                ...state,
                tradingPositions: {
                    ...state.tradingPositions,
                    [action.positionId]: {
                        ...state.tradingPositions[action.positionId],
                        tradingBidId: +action.bidId
                    }
                }
            };
        case actionTypes.SELLER_BIDDING_TRADING_SETTLE_DATE_CHANGE:
            return {
                ...state,
                tradingPositions: {
                    ...state.tradingPositions,
                    [action.positionId]: {
                        ...state.tradingPositions[action.positionId],
                        settleDate: action.date,
                        errors: { settleDate: action.settleDateError }
                    }
                }
            };
        case actionTypes.SELLER_BIDDING_TADING_COMMENT_CHANGE:
            return {
                ...state,
                tradingPositions: {
                    ...state.tradingPositions,
                    [action.positionId]: {
                        ...state.tradingPositions[action.positionId],
                        comment: action.comment
                    }
                }
            };
        // end trade popup
        case actionTypes.SELLER_BIDDING_SHOW_BID_LIST:
            return {
                ...state,
                bidList: {
                    visible: action.payload.visible,
                    positionId: action.payload.positionId
                }
            };

        // Push data
        case pushDataActions.PUSH_DATA_NEW_BID:
            if (!shouldHandlePushData(state, action)) return state;

            return {
                ...state,
                securities: updatePositionBids(action.positionId, action.bid, state.securities)
            };
        case pushDataActions.PUSH_DATA_AXED_FINAL_CHANGE:
            if (!shouldHandlePushData(state, action)) return state;

            return {
                ...state,
                securities: updateAxedFinal(state.securities, action)
            };
        case pushDataActions.PUSH_DATA_NEW_PX_TALK:
            return biddingPushDataReducer(state, action);
        case pushDataActions.PUSH_DATA_QUICK_FEEDBACK:
        case pushDataActions.PUSH_DATA_TRADE:
            return biddingPushDataReducer(state, action)
        case pushDataActions.PUSH_DATA_TARGET_LEVEL:
            if (shouldHandlePushData(state, action)) {
                return {
                    ...state,
                    securities: state.securities.map(s => s.id === action.positionId
                        ? { ...s, targetLevel: action.targetLevel, editTargetLevel: action.targetLevel }
                        : s
                    )
                };
            }

            return state;
        case pushDataActions.PUSH_DATA_OPEN_BIDDING_STAGE2_LEVEL:
            if (!state.securities) return state;

            return {
                ...state,
                securities: state.securities.map(s => (
                    s.id === action.positionId ? {
                        ...s,
                        openBiddingStage2Level: action.level,
                        latestBidSubmission: action.sentDateUtc,
                    } : s
                )),
            };
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_START_STAGE2:
        case pushDataActions.PUSH_DATA_STAGED_BIDDING_FINISH_STAGE2:
            return {
                ...state,
                autoFeedbackSettingsVisible: false
            }
        // Push data end
        case colorDistributionActions.COLOR_DISTRIBUTION_TRADED_TYPE_CHANGE:
            return {
                ...state,
                securities: state.securities.map(s => isActiveTrade(s.trade)
                    ? { ...s, colorDistribution: null }
                    : s),
                colorDistribution: {
                    ...state.colorDistribution,
                    forTradedType: +action.tradedType

                }
            };
        case colorDistributionActions.COLOR_DISTRIBUTION_DNT_TYPE_CHANGE:
            return {
                ...state,
                securities: state.securities.map(s => !s.trade || s.trade.status === TradeStatus.rejected
                    ? { ...s, colorDistribution: null }
                    : s),
                colorDistribution: {
                    ...state.colorDistribution,
                    forDNTType: +action.dntType
                }
            };
        case colorDistributionActions.COLOR_DISTRIBUTION_POSITION_COLOR_TYPE_CHANGE:
            return {
                ...state,
                securities: state.securities.map(s => s.id === action.positionId
                    ? { ...s, colorDistribution: +action.colorType }
                    : s)
            };
        case colorDistributionActions.COLOR_DISTRIBUTION_COMPLETE_IN_PROGRESS:
            return {
                ...state,
                isCompleteInProgress: action.inProgress
            };
        case actionTypes.SELLER_BIDDING_RESET:
        case biddingActions.BIDDING_RESET:
            return { ...initialState };
        case colorDistributionActions.COLOR_DISTRIBUTION_RESET:
            return {
                ...state,
                colorDistribution: initialState.colorDistribution,
                securities: state.securities.map(s => ({ ...s, colorDistribution: null }))
            };
        case actionTypes.LIVE_BIDDING_STAGE_2_TIMEOUT_END:
            return cloneDeep(state);
        case autoFeedbackSettingsActions.AUTO_FEEDBACK_SETTINGS_PANEL_VISIBLE:
            return {
                ...state,
                autoFeedbackSettingsVisible: action.visible
            };
        case autoFeedbackSettingsActions.AUTO_FEEDBACK_SETTINGS_SAVING:
            return {
                ...state,
                autoFeedbackSettingsSaving: action.isSaving
            };
        case autoFeedbackSettingsActions.AUTO_FEEDBACK_BLAST_MESSAGE_SENDING:
            return {
                ...state,
                isAutoFeedbackBlastMessageSending: action.isSending
            };
        case actionTypes.MARK_BIDS_SHOWN:
            return {
                ...state,
                securities: state.securities.map(s =>
                    s.id === action.positionId
                        ? {
                            ...s, bids: (s.bids || []).map(b =>
                                action.bidList.some(bid => biddingUtils.getUniqueBidId(bid) === biddingUtils.getUniqueBidId(b))
                                    ? { ...b, isNew: false }
                                    : b)
                        }
                        : s
                )
            }
        default:
            return state;
    }
}

function updatePositionBids(positionId, bid, targetPositions) {
    if (!targetPositions || !targetPositions.length) {
        return targetPositions;
    }

    const bidLock = {
        companyId: bid.company.id,
        bidLock: bid.modifiedDate
    };

    return targetPositions.map(p => {
        const bidLocks = p.bidLocks || [];
        const bids = p.bids || [];

        return p.id === positionId
            ? {
                ...p,
                // latest bid lock - first
                // ignore direct bid lock
                bidLocks: bid.buyerPseudoOrderNumber ? bidLocks : [bidLock, ...bidLocks],
                bids: bids.concat({ ...bid, isNew: true })
            }
            : p
    });
}

function updateAxedFinal(securities, action) {
    if (!securities || !securities.length) {
        return securities;
    }

    const { positionId, bidId, axed, final, company } = action;

    return securities.map(p => p.id === positionId ? {
        ...p,
        bids: p.bids.map(b => biddingUtils.getUniqueBidId(b) === biddingUtils.getUniqueBidId({ id: bidId, company })
            ? { ...b, axed, final, isNew: true } : b)
    }
        : p
    );
}

function targetLevelReducer(editState, action) {
    switch (action.type) {
        case actionTypes.SELLER_BIDDING_TARGET_LEVEL_REQUEST:
            return {
                ...editState,
                [action.payload.securityId]: {
                    ...editState[action.payload.securityId],
                    targetLevelStatus: RequestState.request
                }
            };
        case actionTypes.SELLER_BIDDING_TARGET_LEVEL_SUCCESS:
            return {
                ...editState,
                [action.payload.securityId]: {
                    ...editState[action.payload.securityId],
                    targetLevelStatus: RequestState.success
                }
            };
        case actionTypes.SELLER_BIDDING_TARGET_LEVEL_FAILURE:
            return {
                ...editState,
                [action.payload.securityId]: {
                    targetLevelStatus: RequestState.failure
                }
            };
        case actionTypes.SELLER_BIDDING_SET_TARGET_LEVEL:
            return {
                ...editState,
                [action.payload.securityId]: {
                    targetLevel: action.payload.value
                }
            };
        default: return editState;
    }
}
