import { ActionType, getType } from "typesafe-actions";
import { inventoryEditActions } from "../actions";
import { InventoryEditState } from "../types/state/InventoryEditState";
import { RequestState } from '../constants/request-state';

const initialState: InventoryEditState = {
    savingRequestState: RequestState.none,
    isConflictsPopupVisible: false,
    requestStateSetTradedStatus: {},
    isFlaggedModalVisible: false,
};

type TInventoryEditAction = ActionType<
    typeof inventoryEditActions.setSavingState |
    typeof inventoryEditActions.setConflictsPopupVisible |
    typeof inventoryEditActions.setTradedRequestStatus |
    typeof inventoryEditActions.setFlaggedModal |
    typeof inventoryEditActions.reset
>;

export const inventoryEdit = (state = initialState, action: TInventoryEditAction): InventoryEditState => {
    switch (action.type) {
        case getType(inventoryEditActions.setSavingState):
            return {
                ...state,
                savingRequestState: action.payload.requestState
            };
        case getType(inventoryEditActions.setConflictsPopupVisible):
            return {
                ...state,
                isConflictsPopupVisible: action.payload.visible
            };
        case getType(inventoryEditActions.setTradedRequestStatus):
            return {
                ...state,
                requestStateSetTradedStatus: {
                    ...state.requestStateSetTradedStatus,
                    [action.payload.inventoryId]: action.payload.status
                }
            };
        case getType(inventoryEditActions.setFlaggedModal):
            return {
                ...state,
                isFlaggedModalVisible: action.payload
            };
        case getType(inventoryEditActions.reset):
            return { ...initialState };
        default:
            return state;
    }
};
