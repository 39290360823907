import { AnyAction } from "redux"
import { UIState } from "../../types/state/ui/UIState"
import { tableInitialState, tableReducer } from "./table.reducer"
import { accountActions } from "../../constants";
import { ActionType } from "typesafe-actions";
import { tableActions } from "../../actions/table.actions";

const initialState: UIState = {
    table: tableInitialState
}

export const ui = (state = initialState, action: AnyAction): UIState => {
    if (action.type === accountActions.LOGOUT) return { ...initialState };

    const [branch, feature] = action.type.split('/');

    if (branch !== 'ui') {
        return state;
    }

    switch (feature) {
        case 'table':
            return {
                ...state,
                table: tableReducer(state.table, action as ActionType<typeof tableActions[keyof typeof tableActions]>)
            }
        default: return state;
    }
}
