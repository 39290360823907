import { inviteToJoinActionTypes } from '../constants';
import { RequestState } from '../constants/request-state';

const initialState = {
    inviteToJoinRequestState: RequestState.none,
    inviteToJoinPopupVisible: false,
    disabledInviteAllButton: false,
};

export const inviteToJoin = (state = initialState, action) => {
    switch (action.type) {
        case inviteToJoinActionTypes.INVITE_TO_JOIN_TOGGLE_VISIBLE_POPUP:
            return {
                ...state,
                inviteToJoinPopupVisible: action.payload.visible
            };
        case inviteToJoinActionTypes.INVITE_TO_JOIN_INVITE_ALL_REQUEST:
            return {
                ...state,
                inviteToJoinRequestState: RequestState.request,
                disabledInviteAllButton: true
            };
        case inviteToJoinActionTypes.INVITE_TO_JOIN_INVITE_ALL_SUCCESS:
            return {
                ...state,
                inviteToJoinRequestState: RequestState.success,
                inviteToJoinPopupVisible: false,
                disabledInviteAllButton: false
            };
        case inviteToJoinActionTypes.INVITE_TO_JOIN_INVITE_ALL_FAILURE:
            return {
                ...state,
                inviteToJoinRequestState: RequestState.failure,
                disabledInviteAllButton: false
            };

        default:
            return state;
    }
}
