import { tradeConfirmActions as actionTypes } from '../constants';
import { TradeStatus } from '../types/trades/TradeStatus';

const initialState = {
    visible: false,
    tradedPositions: [],
    operationsProgress: [] // [{ positionId, isInProgress }]
};

export const tradeConfirm = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CONFIRM_TRADE_POPUP_VISIBLE:
            return {
                ...state,
                visible: action.visible
            };
        case actionTypes.SET_AWAITING_TRADED_POSITIONS:
            return {
                ...state,
                visible: action.tradedPositions && action.tradedPositions.length,
                tradedPositions: state.visible && state.tradedPositions
                    ? mergeTradedPositions(state.tradedPositions, action.tradedPositions)
                    : action.tradedPositions
            };
        case actionTypes.SET_TRADED_POSITION_STATUS:
            return {
                ...state,
                tradedPositions: state.tradedPositions.map(p => action.positions.some(id => id === p.id)
                    ? { ...p, trade: { ...p.trade, status: action.status, rejectReason: action.rejectReason } }
                    : p)
            };
        case actionTypes.REMOVE_PROCESSED_TADED_POSITIONS:
            return {
                ...state,
                tradedPositions: state.tradedPositions.filter(p =>
                    p.trade.status !== TradeStatus.affirmed && p.trade.status !== TradeStatus.rejected)
            };
        case actionTypes.SET_OPERATIONS_PROGRESS: {
            const unchanged = state.operationsProgress.filter(p =>
                !action.operationsProgressUpdate.some(u => u.positionId === p.positionId));

            return {
                ...state,
                operationsProgress: [...unchanged, ...action.operationsProgressUpdate]
            };
        };
        case actionTypes.RESET:
            return {
                ...state,
                visible: false,
                tradedPositions: []
            };
        default:
            return state;
    }
}

function mergeTradedPositions(currentTradedPosition, tradedPositionsUpdate) {
    const newTradedPositions = tradedPositionsUpdate.filter(u => !currentTradedPosition.some(c => c.id === u.id));
    const updatedTradePositions = currentTradedPosition.map(c => tradedPositionsUpdate.find(u => u.id === c.id) ?? c);

    return [...updatedTradePositions, ...newTradedPositions];
}
