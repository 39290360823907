import { NewBwicDealersState } from '../types/state/NewBwicDealersState';
import { ActionType } from '../types/ActionType';
import { newBwicDealersActions } from '../actions/new-bwic-dealers.actions';
import { getType } from 'typesafe-actions';
import { dealerListFilterReducer, initialDealerListFilter } from './dealer-list-filter.reducer';
import { SettlementAgreementStatus } from '../types/bid-as-dealer/SettlementAgreementStatus';

const initialStateNewBwicDealers: NewBwicDealersState = {
    selectedDealers: {},
    selectedByDefault: false,
    filter: initialDealerListFilter,
    selectPlatformDealersPopupVisible: false
};

export const newBwicDealersReducer = (state = initialStateNewBwicDealers, action: ActionType<any>) => {
    switch (action.type) {
        case getType(newBwicDealersActions.selectDealers):
            return {
                ...state,
                selectedDealers: action.payload.selectedDealers
            };
        case getType(newBwicDealersActions.selectDealer):
            return {
                ...state,
                selectedDealers: selectedStateReducer({ ...state.selectedDealers }, action.payload.dealerId)
            };
        case getType(newBwicDealersActions.selectDealersByDefault):
            return {
                ...state,
                selectedByDefault: true
            };
        case getType(newBwicDealersActions.resetSelectedByDefaultFlag):
            return {
                ...state,
                selectedByDefault: false
            };
        case getType(newBwicDealersActions.reset):
            return {
                ...initialStateNewBwicDealers
            };
        case getType(newBwicDealersActions.setSearchTerm):
        case getType(newBwicDealersActions.updateFilterOptions):
        case getType(newBwicDealersActions.clearFilterOptions):
            return {
                ...state,
                filter: dealerListFilterReducer(state.filter, action)
            };
        case getType(newBwicDealersActions.sellerBidAsDealerAgreementsPushDataReceived):
            if (action.payload.agreement.agreementStatus !== SettlementAgreementStatus.confirmed) {
                return state;
            }
            return {
                ...state,
                selectedDealers: selectedStateReducer({ ...state.selectedDealers }, action.payload.agreement.settlementAgent.id)
            };
        case getType(newBwicDealersActions.setSelectPlatformDealersPopupVisible):
            return {
                ...state,
                selectPlatformDealersPopupVisible: action.payload.visible
            };
        default:
            return state;
    }
};

function selectedStateReducer(
    selectedDealers: { [dealerId: number]: boolean },
    dealerId: number): { [dealerId: number]: boolean } {
    if (selectedDealers[dealerId]) {
        delete selectedDealers[dealerId]
    } else {
        selectedDealers[dealerId] = true
    }
    return selectedDealers;
}
