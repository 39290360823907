import { newBWICActions as actionTypes } from '../constants';

export function newBWIC(state = {}, action) {
    switch (action.type) {
        case actionTypes.CHANGE_STEP:
            return {
                ...state,
                activeStep: action.step
            };
        case actionTypes.RESET_WIZARD:
            return {};
        default:
            return state;
    }
};
