import { constants, processActions as actionTypes } from '../constants';
import { estId, getTimeZones } from '../constants/timeZones';
import { dateTimeUtils, formatUtils, numericUtils } from '../utils';

const initialState = {
    daysToSettle: constants.daysToSettleDefault,
    holidays: undefined,
    minimumDate: undefined,
    templates: {
        all: undefined,
        current: undefined,
        dirty: false,
    },
    isValid: true,
    isDateValid: true,
    isTemplateValid: true,
    errors: {
        templates: {},
    },
    disabledHours: null,
    date: undefined,
    nextBusinessDay: undefined,
    bidsDue: undefined,
    goodUntil: undefined,
    isBidsDueMinutesDisabled: false,
    isGoodUntilMinutesDisabled: false,
    timeZone: estId,
    timeZones: getTimeZones(),
    isAllToAll: null
};

export const process = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MIN_DATE:
            return {
                ...state,
                minimumDate: action.minimumDate
            };
        case actionTypes.CHANGE_DATE:
            return {
                ...state,
                date: action.date
            };
        case actionTypes.HOLIDAYS:
            return {
                ...state,
                holidays: action.holidays
            };
        case actionTypes.DAYS_TO_SETTLE:
            return {
                ...state,
                daysToSettle: action.days
            };
        case actionTypes.TIME_ZONE:
            return {
                ...state,
                timeZone: action.timeZone
            };
        case actionTypes.BIDS_DUE:
            return {
                ...state,
                bidsDue: action.time
            };
        case actionTypes.GOOD_UNTIL:
            return {
                ...state,
                goodUntil: action.time
            };
        case actionTypes.SET_DISABLED_TIME:
            return {
                ...state,
                disabledHours: action.disabledHours,
                isBidsDueMinutesDisabled: action.isBidsDueMinutesDisabled,
                isGoodUntilMinutesDisabled: action.isGoodUntilMinutesDisabled
            };
        case actionTypes.VALIDATE:
            return {
                ...state,
                isValid: action.validation.isValid,
                isDateValid: action.validation.isDateValid,
                isTemplateValid: action.validation.isTemplateValid,
                errors: action.validation.errors
            };
        case actionTypes.RESET_IMPROVEMENT_TYPE_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    templates: {
                        ...state.errors.templates,
                        onceAt: null,
                        fixedTimes: null,
                        everyPeriod: null
                    }
                }
            };
        case actionTypes.ADD_TEMPLATES:
            return {
                ...state,
                templates: { ...state.templates, all: action.templates }
            };
        case actionTypes.SELECT_TEMPLATE:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: { ...state.templates.all[action.index] },
                    dirty: false
                },
                errors: {
                    ...state.errors,
                    templates: {}
                }
            };
        case actionTypes.EARLY_TRADES_ENABLED:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        earlyTrades: action.enabled
                    },
                }
            };
        case actionTypes.RESERVE_LEVELS_APPLY_ENABLED:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        reserveLevelsApply: action.enabled
                    },
                }
            };
        case actionTypes.RULES_TEXT:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        rulesText: action.text === '' ? null : action.text
                    }
                }
            };
        case actionTypes.SET_TEMPLATE_DIRTY_STATE:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    dirty: action.dirty
                }
            };
        case actionTypes.TITLE_CHANGE:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    savePrompt: {
                        ...state.templates.savePrompt,
                        template: {
                            ...state.templates.savePrompt.template,
                            title: action.title
                        }
                    }
                }
            };
        case actionTypes.DESCRIPTION_CHANGE:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    savePrompt: {
                        ...state.templates.savePrompt,
                        template: {
                            ...state.templates.savePrompt.template,
                            description: action.description
                        }
                    }
                }
            };
        case actionTypes.NEXT_BUSINESS_DAY:
            return {
                ...state,
                nextBusinessDay: action.date
            }
        case actionTypes.CUT_OFF_REMINDER_MINS:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        cutOffReminder: action.payload.minutes === '' ? '' : dateTimeUtils.timeSpan(0, action.payload.minutes)
                    }
                }
            };
        case actionTypes.RESET_CUT_OFF_REMINDER_MINS:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        cutOffReminder: dateTimeUtils.timeSpan(0, constants.cutOffReminderRangeDefaultMinutes)
                    }
                }
            };
        case actionTypes.AUTO_FEEDBACK_DELAY_MINS:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        autoFeedbackDelay: action.payload.minutes === '' ? '' : dateTimeUtils.timeSpan(0, action.payload.minutes)
                    }
                }
            };
        case actionTypes.AUTO_FEEDBACK_DELAY_ENABLED: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        autoFeedbackDelay: dateTimeUtils.timeSpan(0, constants.autoFeedbackDelayDefaultMinutes)
                    }
                }
            };
        }
        case actionTypes.AUTO_FEEDBACK_DELAY_DISABLED: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        autoFeedbackDelay: null
                    }
                }
            };
        }
        case actionTypes.MIN_BID_INCREMENT_CHANGED:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        minBidIncrement: action.payload.value
                    }
                }
            };
        case actionTypes.MIN_BID_INCREMENT_ENABLED: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        minBidIncrement: formatUtils.formatBid(constants.defaultMinBidIncrement)
                    }
                }
            };
        }
        case actionTypes.MIN_BID_INCREMENT_DISABLED: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        minBidIncrement: null
                    }
                }
            };
        }
        case actionTypes.JUMP_BALL_STAGE_1_END_MANUAL:
        case actionTypes.JUMP_BALL_STAGE_1_END_AUTO:
        case actionTypes.JUMP_BALL_STAGE_1_END_AUTO_MINS:
        case actionTypes.JUMP_BALL_STAGE_2_PARTICIPANTS_CHANGE:
        case actionTypes.JUMP_BALL_STAGE_2_DURATION_MINS:
        case actionTypes.JUMP_BALL_STAGE_2_DURATION_UNSPECIFIED:
        case actionTypes.JUMP_BALL_STAGE_2_DURATION_PRESET:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        jumpBall: jumpBallSettingsReducer(state.templates.current.jumpBall || {}, action)
                    }
                }
            }
        case actionTypes.TOP_X_STAGE_1_END_MANUAL:
        case actionTypes.TOP_X_STAGE_1_END_AUTO:
        case actionTypes.TOP_X_STAGE_1_END_AUTO_MINS:
        case actionTypes.TOP_X_STAGE_2_PARTICIPANTS_CHANGE:
        case actionTypes.TOP_X_STAGE_2_DURATION_MINS:
        case actionTypes.TOP_X_STAGE_2_DURATION_UNSPECIFIED:
        case actionTypes.TOP_X_STAGE_2_DURATION_PRESET:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        topX: topXSettingsReducer(state.templates.current.topX || {}, action)
                    }
                }
            }
        case actionTypes.BEST_FOOT_FORWARD_DURATION_MINS:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        bff: bestFootForwardSettingsReducer(state.templates.current.bff || {}, action)
                    }
                }
            }
        case actionTypes.SET_STAGE1_DEADLINE:
        case actionTypes.SET_IMPROVEMENT_ROUND:
        case actionTypes.SET_MIN_BID_INCREMENT:
        case actionTypes.SET_LEVEL_SPECIFICATION_TYPE:
        case actionTypes.SET_OPEN_BIDDING_IMPROVER_COUNT:
            return {
                ...state,
                templates: {
                    ...state.templates,
                    current: {
                        ...state.templates.current,
                        liveBidding: liveBiddingSettingsReducer(state.templates.current.liveBidding || {}, action)
                    }
                }
            }
        case actionTypes.SET_ALL_TO_ALL_PROTOCOL:
            return {
                ...state,
                isAllToAll: action.payload,
            };
        case actionTypes.RESET_CHANGES: {
            const current = state.templates.all.find(t => t.id === state.templates.current?.id);
            return current ? {
                ...state,
                templates: {
                    ...state.templates,
                    current,
                    dirty: false
                },
                errors: {
                    ...state.errors,
                    template: {}
                }
            } : state;
        }
        case actionTypes.RESET:
            return { ...initialState };
        default:
            return state;
    }
}

function jumpBallSettingsReducer(jumpBallSettings = {}, action) {
    switch (action.type) {
        case actionTypes.JUMP_BALL_STAGE_1_END_MANUAL:
            return {
                ...jumpBallSettings,
                automaticStage1Deadline: undefined
            };
        case actionTypes.JUMP_BALL_STAGE_1_END_AUTO:
            return {
                ...jumpBallSettings,
                automaticStage1Deadline: dateTimeUtils.timeSpan(0, constants.jumpBall.stage1EndAutoDefaultMinutes)
            };
        case actionTypes.JUMP_BALL_STAGE_1_END_AUTO_MINS:
            return {
                ...jumpBallSettings,
                automaticStage1Deadline: action.payload.minutes === '' ? '' : dateTimeUtils.timeSpan(0, action.payload.minutes)
            };
        case actionTypes.JUMP_BALL_STAGE_2_PARTICIPANTS_CHANGE:
            return {
                ...jumpBallSettings,
                improverCount: action.payload.participants
            };
        case actionTypes.JUMP_BALL_STAGE_2_DURATION_MINS:
            return {
                ...jumpBallSettings,
                improvementRound: action.payload.minutes === '' ? '' : dateTimeUtils.timeSpan(0, action.payload.minutes)
            };
        case actionTypes.JUMP_BALL_STAGE_2_DURATION_UNSPECIFIED:
            return {
                ...jumpBallSettings,
                improvementRound: undefined
            };
        case actionTypes.JUMP_BALL_STAGE_2_DURATION_PRESET:
            return {
                ...jumpBallSettings,
                improvementRound: dateTimeUtils.timeSpan(0, constants.jumpBall.stage2DurationDefaultMinutes)
            };
        default: return jumpBallSettings;
    }
}

function topXSettingsReducer(topXSettings = {}, action) {
    switch (action.type) {
        case actionTypes.TOP_X_STAGE_1_END_MANUAL:
            return {
                ...topXSettings,
                automaticStage1Deadline: undefined
            };
        case actionTypes.TOP_X_STAGE_1_END_AUTO:
            return {
                ...topXSettings,
                automaticStage1Deadline: dateTimeUtils.timeSpan(0, constants.jumpBall.stage1EndAutoDefaultMinutes)
            };
        case actionTypes.TOP_X_STAGE_1_END_AUTO_MINS:
            return {
                ...topXSettings,
                automaticStage1Deadline: action.payload.minutes === '' ? '' : dateTimeUtils.timeSpan(0, action.payload.minutes)
            };
        case actionTypes.TOP_X_STAGE_2_PARTICIPANTS_CHANGE:
            return {
                ...topXSettings,
                improverCount: action.payload.participants
            };
        case actionTypes.TOP_X_STAGE_2_DURATION_MINS:
            return {
                ...topXSettings,
                improvementRound: action.payload.minutes === '' ? '' : dateTimeUtils.timeSpan(0, action.payload.minutes)
            };
        case actionTypes.TOP_X_STAGE_2_DURATION_UNSPECIFIED:
            return {
                ...topXSettings,
                improvementRound: undefined
            };
        case actionTypes.TOP_X_STAGE_2_DURATION_PRESET:
            return {
                ...topXSettings,
                improvementRound: dateTimeUtils.timeSpan(0, constants.jumpBall.stage2DurationDefaultMinutes)
            };
        default: return topXSettings;
    }
}

function bestFootForwardSettingsReducer(bestFootForwardSettings = {}, action) {
    switch (action.type) {
        case actionTypes.BEST_FOOT_FORWARD_DURATION_MINS:
            return {
                ...bestFootForwardSettings,
                stage1Deadline: action.payload.minutes === '' ? '' : dateTimeUtils.timeSpan(0, action.payload.minutes)
            };

        default: return bestFootForwardSettings;
    }
}

function liveBiddingSettingsReducer(liveBiddingSettings = {}, action) {
    switch (action.type) {
        case actionTypes.SET_STAGE1_DEADLINE:
            return {
                ...liveBiddingSettings,
                stage1Deadline: numericUtils.numberOrDefault(action.payload.value)
            };
        case actionTypes.SET_IMPROVEMENT_ROUND:
            return {
                ...liveBiddingSettings,
                improvementRound: numericUtils.numberOrDefault(action.payload.value)
            };
        case actionTypes.SET_MIN_BID_INCREMENT:
            return {
                ...liveBiddingSettings,
                minBidIncrement: action.payload.value,
            };
        case actionTypes.SET_LEVEL_SPECIFICATION_TYPE:
            return {
                ...liveBiddingSettings,
                levelSpecificationType: action.payload.value,
            };
        case actionTypes.SET_OPEN_BIDDING_IMPROVER_COUNT:
            return {
                ...liveBiddingSettings,
                openBiddingImproverCount: action.payload.value,
            };


        default: return liveBiddingSettings;
    }
}