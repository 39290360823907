import { submitActions as actionTypes } from '../constants';
import { RequestState } from '../constants/request-state';

const initialState = {
    userCompany: undefined,
    savedBwicReferenceName: null,
    submitState: RequestState.none
}

export const submit = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_USER_COMPANY:
            return {
                ...state,
                userCompany: action.company 
            };
        case actionTypes.SUBMIT_STATE:
            return {
                ...state,
                savedBwicReferenceName: action.bwicReferenceName,
                submitState: action.state
            };
        case actionTypes.RESET:
            return {};
        default:
            return state;
    }
}
