import { ActionType, getType } from 'typesafe-actions';
import { manageCompaniesFiltersActions } from '../actions/manage-companies-filter.actions';
import { CompanyFilterState } from '../types/state/ManageCompaniesState';
import xor from 'lodash/xor';

export const manageCompaniesFiltersReducer = (
    state: CompanyFilterState,
    action: ActionType<typeof manageCompaniesFiltersActions[keyof typeof manageCompaniesFiltersActions]>): CompanyFilterState => {
    switch (action.type) {
        case getType(manageCompaniesFiltersActions.setDateFilterChange):
            return {
                ...state,
                selectedDateOption: action.payload.dateOption,
            }
        case getType(manageCompaniesFiltersActions.setDateFilterRangeChange):
            return {
                ...state,
                customDateRange: action.payload.dateRange,
            }
        case getType(manageCompaniesFiltersActions.setFilterRegBy):
            return {
                ...state,
                regBy: action.payload.regBy
            }
        case getType(manageCompaniesFiltersActions.setFilterSalesRepresentative):
            return {
                ...state,
                salesRepresentatives: action.payload.salesRepresentatives
            }
        case getType(manageCompaniesFiltersActions.toogleFilterRegBy):
            return {
                ...state,
                regBy: xor(action.payload.regBy, state.regBy)
            }
        case getType(manageCompaniesFiltersActions.toogleFilterSalesRepresentative):
            return {
                ...state,
                salesRepresentatives: xor(action.payload.salesRepresentatives, state.salesRepresentatives)
            }
        case getType(manageCompaniesFiltersActions.toogleFilterRoleAdministrator):
            return {
                ...state,
                administrator: !state.administrator
            }
        case getType(manageCompaniesFiltersActions.toogleFilterRoleBrokerDealer):
            return {
                ...state,
                brokerDealer: !state.brokerDealer
            }
        case getType(manageCompaniesFiltersActions.toogleFilterRoleSeller):
            return {
                ...state,
                seller: !state.seller
            }
        case getType(manageCompaniesFiltersActions.toogleFilterRoleViewer):
            return {
                ...state,
                viewer: !state.viewer
            }
        case getType(manageCompaniesFiltersActions.toogleFilterRoleMedia):
            return {
                ...state,
                media: !state.media
            }
        case getType(manageCompaniesFiltersActions.toogleFilterStatusActive):
            return {
                ...state,
                active: !state.active

            }
        case getType(manageCompaniesFiltersActions.toogleFilterStatusBlocked):
            return {
                ...state,
                blocked: !state.blocked
            }
        case getType(manageCompaniesFiltersActions.toogleFilterStatusOffPlatform):
            return {
                ...state,
                offPlatform: !state.offPlatform
            }
        default:
            return state;
    }
}