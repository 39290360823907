import { securitiesActions as actionTypes } from '../constants';
import { RequestState } from '../constants/request-state';

const initialState = {
    isInitializing: false,
    isTradeLimitsValid: true,
    isFlaggedModalVisible: false,
    validateTradeLimitsRequestState: RequestState.none,
};

export const securities = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZING:
            return {
                ...state,
                isInitializing: action.isInitializing
            };
        case actionTypes.RESET:
            return initialState;
        case actionTypes.VALIDATE_TRADE_LIMITS_REQUEST:
            return {
                ...state,
                validateTradeLimitsRequestState: RequestState.request
            };
        case actionTypes.VALIDATE_TRADE_LIMITS_SUCCESS:
            return {
                ...state,
                validateTradeLimitsRequestState: RequestState.success
            };
        case actionTypes.VALIDATE_TRADE_LIMITS_ERROR:
            return {
                ...state,
                validateTradeLimitsRequestState: RequestState.failure
            };
        case actionTypes.SECURITIES_SET_FLAGGED_MODAL:
            return {
                ...state,
                isFlaggedModalVisible: action.isFlaggedModalVisible
            };
        default:
            return state;
    }
}
