import { getType } from "typesafe-actions";
import { PriceTalksState } from "../types/state/PriceTalksState";
import { priceTalksActions, TPriceTalksActionType } from '../actions/price-talks.actions';
import { RequestState } from "../constants/request-state";
import { keys } from "lodash";

export const priceTalksInitialState: PriceTalksState = {
    priceTalks: {}
}

export function priceTalksReducer(state = priceTalksInitialState, action: TPriceTalksActionType): PriceTalksState {
    switch (action.type) {
        case getType(priceTalksActions.request):
            return {
                ...state,
                priceTalks: {
                    ...state.priceTalks,
                    [action.payload.positionId]: {
                        requestState: RequestState.request,
                        items: []
                    }
                }
            };
        case getType(priceTalksActions.requestResult):
            return {
                ...state,
                priceTalks: {
                    ...state.priceTalks,
                    [action.payload.positionId]: {
                        requestState: action.payload.requestState,
                        items: action.payload.priceTalks ?? []
                    }
                }
            };
        case getType(priceTalksActions.addPriceTalk):
            if (state.priceTalks[action.payload.positionId]) {
                return {
                    ...state,
                    priceTalks: {
                        ...state.priceTalks,
                        [action.payload.positionId]: {
                            ...state.priceTalks[action.payload.positionId],
                            items: state.priceTalks[action.payload.positionId].items.some(i => i.company.id === action.payload.priceTalk.company.id)
                                ? state.priceTalks[action.payload.positionId].items.map(
                                    i => i.company.id === action.payload.priceTalk.company.id ? action.payload.priceTalk : i)
                                : state.priceTalks[action.payload.positionId].items.concat(action.payload.priceTalk)
                        }
                    }
                };
            }

            return {
                ...state,
                priceTalks: {
                    ...state.priceTalks,
                    [action.payload.positionId]: {
                        requestState: RequestState.success,
                        items: [action.payload.priceTalk]
                    }
                }
            };
        case getType(priceTalksActions.replacePriceTalks):
            return {
                ...state,
                priceTalks: {
                    ...state.priceTalks,
                    [action.payload.positionId]: {
                        requestState: RequestState.success,
                        items: action.payload.priceTalks
                    }
                }
            };
        case getType(priceTalksActions.resetFailed): {
            const copy = { ...state.priceTalks };

            keys(state.priceTalks).forEach((key: string) => {
                if (copy[+key].requestState === RequestState.failure) {
                    delete copy[+key];
                }
            });

            return {
                ...state,
                priceTalks: copy
            };
        }
        case getType(priceTalksActions.reset):
            return { ...priceTalksInitialState };
        default: return state;
    };
}
