import { ActionType, getType } from 'typesafe-actions';
import xor from 'lodash/xor';
import { manageMembersFiltersActions } from '../actions/manage-members-filter.actions';
import { MemberFilterState } from '../types/state/ManageCompaniesState';

export const manageMembersFiltersReducer = (
    state: MemberFilterState,
    action: ActionType<typeof manageMembersFiltersActions[keyof typeof manageMembersFiltersActions]>
): MemberFilterState => {
    switch (action.type) {
        case getType(manageMembersFiltersActions.setDateFilterChange):
            return {
                ...state,
                selectedDateOption: action.payload.dateOption
            }
        case getType(manageMembersFiltersActions.setDateFilterRangeChange):
            return {
                ...state,
                customDateRange: action.payload.dateRange
            }
        case getType(manageMembersFiltersActions.setFilterRegBy):
            return {
                ...state,
                regBy: action.payload.regBy
            }
        case getType(manageMembersFiltersActions.toogleFilterRegBy):
            return {
                ...state,
                regBy: xor(action.payload.regBy, state.regBy)
            }
        case getType(manageMembersFiltersActions.setFilterCompanies):
            return {
                ...state,
                companies: action.payload.companies
            }
        case getType(manageMembersFiltersActions.toogleFilterCompanies):
            return {
                ...state,
                companies: xor(action.payload.companies, state.companies)
            }
        case getType(manageMembersFiltersActions.toogleFilterRoleAdministrator):
            return {
                ...state,
                administrator: !state.administrator
            }
        case getType(manageMembersFiltersActions.toggleFilterRoleArrangersClient):
            return {
                ...state,
                arrangersClient: !state.arrangersClient
            }
        case getType(manageMembersFiltersActions.toogleFilterRoleBrokerDealerTrader):
            return {
                ...state,
                brokerDealerTrader: !state.brokerDealerTrader
            }
        case getType(manageMembersFiltersActions.toogleFilterRoleBrokerDealerViewer):
            return {
                ...state,
                brokerDealerViewer: !state.brokerDealerViewer
            }
        case getType(manageMembersFiltersActions.toogleFilterRolesDataEntry):
            return {
                ...state,
                dataEntry: !state.dataEntry
            }
        case getType(manageMembersFiltersActions.toogleFilterRoleSellerAdministrator):
            return {
                ...state,
                sellerAdmin: !state.sellerAdmin
            }
        case getType(manageMembersFiltersActions.toogleFilterRoleSellerTrader):
            return {
                ...state,
                sellerTrader: !state.sellerTrader
            }
        case getType(manageMembersFiltersActions.toogleFilterRoleSellerViewer):
            return {
                ...state,
                sellerViewer: !state.sellerViewer
            }
        case getType(manageMembersFiltersActions.toogleFilterRolesSubscriptionManager):
            return {
                ...state,
                subscriptionManager: !state.subscriptionManager
            }
        case getType(manageMembersFiltersActions.toogleFilterRoleViewer):
            return {
                ...state,
                viewer: !state.viewer
            }
        case getType(manageMembersFiltersActions.toogleFilterRoleMedia):
            return {
                ...state,
                media: !state.media
            }
        case getType(manageMembersFiltersActions.toogleFilterStatusActive):
            return {
                ...state,
                active: !state.active

            }
        case getType(manageMembersFiltersActions.toogleFilterStatusBlocked):
            return {
                ...state,
                blocked: !state.blocked
            }
        case getType(manageMembersFiltersActions.toogleFilterStatusInvited):
            return {
                ...state,
                invited: !state.invited
            }

        case getType(manageMembersFiltersActions.toogleFilterStatusNotInvited):
            return {
                ...state,
                notInvited: !state.notInvited
            }
        case getType(manageMembersFiltersActions.toogleFilterStatusPendingApproval):
            return {
                ...state,
                pendingApproval: !state.pendingApproval
            }
        case getType(manageMembersFiltersActions.toogleFilterCollateralManager):
            return {
                ...state,
                collateralManager: !state.collateralManager
            }
        default:
            return state;
    }
}
