import { searchSecuritiesActions as actionTypes } from '../constants';

const initialState = {
    searchTerm: '',
    searchTermItems: [],
    markerPosition: -1,
    lookup: [],
    lookupCache: [],
    searchTermCache: '',
}

export const searchSecurities = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEARCH_TERM_CHANGE:
            return {
                ...state,
                searchTerm: action.search.searchTerm,
                lookup: action.search.searchTerm
                    ? (state.lookup.map((e, i) => i === 0 ? action.search.searchTerm : e))
                    : state.lookup
            };
        case actionTypes.SEARCH_LOOKUP_CACHE:
            return {
                ...state,
                lookupCache: action.lookupData,
                lookup: [state.searchTerm, ...action.lookupData],
                searchTermCache: action.searchTerm
            };
        case actionTypes.SEARCH_LOOKUP:
            return {
                ...state,
                lookup: [state.searchTerm, ...action.lookupData],
            };
        case actionTypes.SECURITY_SEARCH_ITEM_ADD:
            return {
                ...state,
                searchTermItems: [...state.searchTermItems, action.payload.item],
                markerPosition: -1,
            };
        case actionTypes.SECURITY_SEARCH_ITEM_REMOVE:
            return {
                ...state,
                searchTermItems: state.searchTermItems.filter((s, i) => i !== action.payload.index),
                markerPosition: getMarkerPositionAfterItemRemove(state.markerPosition, action.payload.index),
            };
        case actionTypes.SECURITY_SEARCH_REMOVE_CURRENT_ITEM:
            if (state.markerPosition > -1 && state.markerPosition < state.searchTermItems.length) {
                return {
                    ...state,
                    searchTermItems: state.searchTermItems.filter((s, i) => i !== state.markerPosition),
                };
            } else {
                return state;
            }
        case actionTypes.SECURITY_SEARCH_MOVE_BACK:
        case actionTypes.SECURITY_SEARCH_MOVE_FORWARD:
            return searchItemNavigationReducer(state, action);
        case actionTypes.RESET:
            return { ...initialState };
        default:
            return state;
    }
}

function searchItemNavigationReducer(state = initialState, action) {
    let markerPosition = state.markerPosition;
    if (action.type === actionTypes.SECURITY_SEARCH_MOVE_BACK) {
        if (markerPosition > 0) {
            return { ...state, markerPosition: --markerPosition };
        }
        if (markerPosition === -1) {
            return { ...state, markerPosition: state.searchTermItems.length - 1 };
        }
    }
    if (action.type === actionTypes.SECURITY_SEARCH_MOVE_FORWARD) {
        if (markerPosition >= 0 && markerPosition < state.searchTermItems.length - 1) {
            return { ...state, markerPosition: ++markerPosition };
        }
        return { ...state, markerPosition: -1 };
    }
    return state
}

function getMarkerPositionAfterItemRemove(markerPosition, removedIndex) {
    return (markerPosition > removedIndex) ? (markerPosition - 1) : markerPosition;
}
