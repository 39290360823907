import { accountActions, profileActions } from '../constants';
import { sellerAdminUsersActions } from '../actions/seller-admin-users.actions';
import { AnyAction } from 'redux';
import { ProfileState } from '../types/state/ProfileState';
import { formatUtils } from '../utils';
import { getType } from "typesafe-actions";
import { RequestState } from '../constants/request-state';

const initState: ProfileState = {
    initStatus: RequestState.none,
    profile: undefined,
    company: undefined,
    error: null,
    isLoading: false,
    isSmsConfirmationDeactivating: false,
    isIdentityPhoneSaving: false,
    isIdentityPhoneEdit: false,
    isPhoneVerificationCodeSending: false,
    isPhoneVerificationCodeInvalid: false,
    smsActivationCodeRequestedDate: undefined,
    changePhoneVisible: false,
    changeBloombergEmailVisible: false,
    requestStatusSaveBloombergEmail: false,
    changeContactPopupVisible: false,
    contactId: undefined,
    changePasswordVisible: false,
    isUploadUserProfilePhotoPopupVisible: false,
    isUserProfilePhotoUploading: false,
    isUserProfilePhotoDeleting: false,
};

export function profile(state: ProfileState = initState, action: AnyAction): ProfileState {
    switch (action.type) {
        case profileActions.PROFILE_INIT:
            return {
                ...state,
                initStatus: action.requestState,
            };
        case profileActions.GET_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.profile
            };
        case profileActions.GET_PROFILE_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload.company
            };
        case profileActions.CHANGE_PHONE_VISIBLE:
            return {
                ...state,
                changePhoneVisible: action.changePhoneVisible,
                error: null
            };
        case profileActions.TOGGLE_VISIBILITY_FORM_BLOOMBERG_EMAIL:
            return {
                ...state,
                changeBloombergEmailVisible: !state.changeBloombergEmailVisible
            };
        case profileActions.IS_REQUEST_SAVE_BLOOMBERG_EMAIL:
            return {
                ...state,
                requestStatusSaveBloombergEmail: action.payload.status,
                changeBloombergEmailVisible: action.payload.status
            };
        case profileActions.SAVE_PHONE_NUMBER:
            return {
                ...state,
                profile: action.profile
            };
        case profileActions.CHANGE_PHONE_NUMBER_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case profileActions.CHANGE_CONTACT_POPUP_VISIBLE:
            return {
                ...state,
                changeContactPopupVisible: action.contact.changeContactPopupVisible,
                contactId: action.contact.contactId
            };
        case profileActions.CHANGE_PASSWORD_FORM_VISIBLE:
            return {
                ...state,
                changePasswordVisible: action.changePasswordVisible
            };
        case accountActions.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordVisible: false
            };
        case profileActions.PROFILE_COMPANY_CONTACT_CHANGED:
            return {
                ...state,
                company: state.company
                    ? {
                        ...state.company,
                        departmentContacts: state.company.departmentContacts.map(c => {
                            if (c.departmentValue === action.payload.departmentValue) {
                                return {
                                    ...c,
                                    firstName: action.payload.submitModel.firstName,
                                    lastName: action.payload.submitModel.lastName,
                                    fullName: `${action.payload.submitModel.firstName} ${action.payload.submitModel.lastName}`,
                                    title: action.payload.submitModel.jobTitle,
                                    phone: action.payload.submitModel.phone,
                                    email: action.payload.submitModel.email,
                                }
                            }
                            return c;
                        })
                    }
                    : undefined
            };

        case profileActions.PROFILE_COMPANY_ADD_NEW_TRADER:
        case profileActions.PROFILE_COMPANY_EDIT_TRADER:
        case profileActions.PROFILE_COMPANY_EDIT_CONTACT:
            return {
                ...state,
                company: action.payload.company,
            };
        case getType(sellerAdminUsersActions.changeBwicAnnouncementNotifications):
            return {
                ...state,
                company: state.company
                    ? {
                        ...state.company,
                        bwicCreateNotifyAdmins: action.payload.bwicCreateNotifyAdmins,
                        bwicCreateNotifyViewers: action.payload.bwicCreateNotifyViewers,
                    }
                    : state.company
            }
        case profileActions.ACCOUNT_SETTINGS_RESET:
            return { ...initState };

        case profileActions.ENABLE_COMPANY_TRADER_REQUEST:
        case profileActions.DISABLE_COMPANY_TRADER_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case profileActions.ENABLE_COMPANY_TRADER_SUCCESS:
        case profileActions.DISABLE_COMPANY_TRADER_SUCCESS:
        case profileActions.ENABLE_COMPANY_TRADER_FAIL:
        case profileActions.DISABLE_COMPANY_TRADER_FAIL:
            return {
                ...state,
                isLoading: false
            };
        case profileActions.PROFILE_SMS_DEACTIVATING:
            return {
                ...state,
                isSmsConfirmationDeactivating: action.inProgress
            };
        case profileActions.PROFILE_SMS_DEACTIVATED:
            return {
                ...state,
                profile: state.profile
                    ? { ...state.profile, identityPhone: '', identityPhoneConfirmed: false }
                    : state.profile
            };
        case profileActions.PROFILE_SMS_ACTIVATION_CODE_REQUESTED:
            return {
                ...state,
                smsActivationCodeRequestedDate: action.date
            };
        case profileActions.PROFILE_IDENTITY_PHONE_SAVING_STATE:
            return {
                ...state,
                isIdentityPhoneSaving: action.inProgress
            };
        case profileActions.PROFILE_IDENTITY_PHONE_SAVED:
            return {
                ...state,
                profile: state.profile
                    ? {
                        ...state.profile,
                        identityPhone: action.phone,
                        maskedIdentityPhone: formatUtils.maskPhone(action.phone),
                        identityPhoneConfirmed: false
                    }
                    : state.profile
            };
        case profileActions.PROFILE_IDENTITY_PHONE_CONFIRMED:
            return {
                ...state,
                isIdentityPhoneEdit: false,
                isPhoneVerificationCodeInvalid: false,
                smsActivationCodeRequestedDate: undefined,
                profile: state.profile
                    ? { ...state.profile, identityPhoneConfirmed: true }
                    : state.profile
            };
        case profileActions.PROFILE_VERIFICATION_CODE_SENDING_STATE:
            return {
                ...state,
                isPhoneVerificationCodeSending: action.inProgress
            };
        case profileActions.PROFILE_PHONE_VERIFICATION_CODE_VALID_STATE:
            return {
                ...state,
                isPhoneVerificationCodeInvalid: action.isValid
            };
        case profileActions.PROFILE_IDENTITY_PHONE_EDIT:
            return {
                ...state,
                isIdentityPhoneEdit: action.isEdit
            };
        case profileActions.PROFILE_SET_VISIBLE_UPLOAD_USER_IMAGE_POPUP:
            return {
                ...state,
                isUploadUserProfilePhotoPopupVisible: action.payload.visible
            };
        case profileActions.PROFILE_SET_REQUEST_STATE_UPLOAD_USER_IMAGE:
            return {
                ...state,
                isUserProfilePhotoUploading: action.payload.requestState
            };
        case profileActions.PROFILE_SET_REQUEST_STATE_DELETE_USER_IMAGE:
            return {
                ...state,
                isUserProfilePhotoDeleting: action.payload.requestState
            };
        default:
            return state;
    }
}
