import { AnyAction } from "redux";
import { VerifyIdentityState } from "../types/state/VerifyIdentityState";
import { VerifyIdentityMethodType } from "../types/account/VerifyIdentityMethodType";
import { verifyIdentityActions as actionTypes } from "../constants/actionTypes/verify.identity.actions";
import { accountActions } from "../constants";

const initialState: VerifyIdentityState = {
    selectedMethod: VerifyIdentityMethodType.email,
    isVerificationRequired: false,
    isVerificationCodeRequesting: false,
    isVerificationCodeConfirming: false,
    isVerificationCodeInvalid: false,
    verificationCodeSentDate: undefined,
    emailCode: '',
    phoneCode: undefined,
    pathname: undefined
}

export function verifyIdentity(state: VerifyIdentityState = initialState, action: AnyAction): VerifyIdentityState {
    switch (action.type) {
        case actionTypes.VERIFY_IDENTITY_SET_SELECTED_METHOD:
            return {
                ...state,
                selectedMethod: action.selectedMethod
            };
        case actionTypes.VERIFY_IDENTITY_REQUIRED:
            return {
                ...state,
                isVerificationRequired: true,
                emailCode: action.email,
                phoneCode: action.phone,
                pathname: action.pathname
            };
        case actionTypes.VERIFY_IDENTITY_CODE_REUESTING:
            return {
                ...state,
                isVerificationCodeRequesting: action.isRequesting
            };
        case actionTypes.VERIFY_IDENTITY_CODE_CONFIRMING:
            return {
                ...state,
                isVerificationCodeConfirming: action.inProgress
            };
        case actionTypes.VERIFY_IDENTITY_CODE_SENT:
            return {
                ...state,
                verificationCodeSentDate: action.sentDate
            };
        case actionTypes.VERIFY_IDENTITY_CODE_INVALID:
            return {
                ...state,
                isVerificationCodeInvalid: action.isInvalid
            };
        case actionTypes.VERIFY_IDENTITY_RESET:
        case accountActions.LOGIN_SUCCESS:
        case accountActions.LOGIN_FAILURE:
            return { ...initialState };
        default: return state;
    }
}
