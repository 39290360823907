import { getType } from 'typesafe-actions';
import { textRulesPanelActions, TRulesSidePanelActions } from '../actions/text-rules-panel.actions';
import { TextRulesPanelState } from '../types/state/TextRulesPanelState';

const initialState: TextRulesPanelState = {
    visible: false,
    params: null
}

export const textRulesPanel = (state = initialState, action: TRulesSidePanelActions) => {
    switch (action.type) {
        case getType(textRulesPanelActions.show):
            return {
                params: {
                    goodUntilDate: action.payload.goodUntilDate,
                    process: action.payload.process,
                    parsedProcess: action.payload.parsedProcess,
                    bidsDueDate: action.payload.bidsDueDate,
                    isParsed: action.payload.isParsed,
                    rulesText: action.payload.rulesText,
                    isSameDayBwic: action.payload.isSameDayBwic,
                    daysToSettle: action.payload.daysToSettle,
                    bwicReferenceName: action.payload.bwicReferenceName
                },
                visible: true
            }
        case getType(textRulesPanelActions.hide):
            return {
                params: null,
                visible: false
            }
        default:
            return state;
    }
}
