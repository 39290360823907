import { ActionType } from '../types/ActionType';
import { sellerAdminDealerActions } from '../actions/seller-admin-dealers.actions';
import { getType } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { SellerAdminDealersState } from '../types/state/SellerAdminDealersState';

const initialState: SellerAdminDealersState = {
    excludeDealers: [],
    excludeNewCompany: undefined,
    requestStatusGetExcludeDealers: RequestState.none,
    requestStateSaveExcludeDealers: RequestState.none,
    editMode: false,
};

export const sellerAdminDealers = (state = initialState, action: ActionType): SellerAdminDealersState => {
    switch (action.type) {
        case getType(sellerAdminDealerActions.setEditModeStatus):
            return {
                ...state,
                editMode: action.payload.status
            };
        case getType(sellerAdminDealerActions.getExcludedDealersRequest):
            return {
                ...state,
                requestStatusGetExcludeDealers: RequestState.request
            };
        case getType(sellerAdminDealerActions.getExcludedDealersSuccess):
            return {
                ...state,
                requestStatusGetExcludeDealers: RequestState.success,
                excludeDealers: action.payload.dealers,
                excludeNewCompany: action.payload.excludeNewCompany
            };
        case getType(sellerAdminDealerActions.getExcludedDealersFailure):
            return {
                ...state,
                requestStatusGetExcludeDealers: RequestState.failure
            };
        case getType(sellerAdminDealerActions.saveExcludedDealersRequest):
            return {
                ...state,
                requestStateSaveExcludeDealers: RequestState.request
            };
        case getType(sellerAdminDealerActions.saveExcludedDealersSuccess):
            return {
                ...state,
                requestStateSaveExcludeDealers: RequestState.success,
            };
        case getType(sellerAdminDealerActions.saveExcludedDealersFailure):
            return {
                ...state,
                requestStateSaveExcludeDealers: RequestState.failure
            };
        case getType(sellerAdminDealerActions.reset):
            return {
                ...initialState
            };
        default:
            return state;
    }
};
