import { ManageEmailFilterState } from '../types/state/ManageEmailState';
import { bwicDateFilterOptions } from '../constants';
import { manageEmailActions, manageEmailActionsType } from '../actions/manage-email.actions';
import { getType } from 'typesafe-actions';

export const defaultManageEmailFilterFilter: ManageEmailFilterState = {
    customDateRange: {},
    selectedDateOption: bwicDateFilterOptions.unspecified,
    types: [],
    statuses: [],
}

const initialState = {
    initialFilter: defaultManageEmailFilterFilter,
    filter: { ...defaultManageEmailFilterFilter, selectedDateOption: bwicDateFilterOptions.todayAndUpcoming }
}

export const manageEmailReducer = (state = initialState, action: manageEmailActionsType) => {
    switch (action.type) {
        case getType(manageEmailActions.setFilter):
            return {
                ...state,
                filter: action.payload.filter,
            }
        default:
            return state;
    }
}
