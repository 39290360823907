import { SellerAdminLimitState } from '../types/state/SellerAdminLimitState';
import { RequestState } from '../constants/request-state';
import { ActionType } from '../types/ActionType';
import { getType } from 'typesafe-actions';
import { sellerAdminLimitActions } from '../actions/seller-admin-limit.actions';
import { TradingLimitType } from '../types/seller-admin/TradingLimitType';
import { Currency } from '../types/enums/Currency';

const initialTradingLimitsValue = {
    [Currency.USD]: null,
    [Currency.EUR]: null,
};

const initialState: SellerAdminLimitState = {
    getLimitsRequestStatus: RequestState.none,
    updateLimitsRequestStatus: RequestState.none,
    editMode: false,
    tradingLimits: {
        [TradingLimitType.SellMaxBondSize]: { ...initialTradingLimitsValue },
        [TradingLimitType.SellMaxDailyTradesSize]: { ...initialTradingLimitsValue },
        [TradingLimitType.BuyMaxBondSize]: { ...initialTradingLimitsValue },
        [TradingLimitType.BuyMaxDailyTradesSize]: { ...initialTradingLimitsValue }
    }
};

export const sellerAdminLimit = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case getType(sellerAdminLimitActions.setEditModeStatus):
            return {
                ...state,
                editMode: action.payload.status
            };
        case getType(sellerAdminLimitActions.getTradeLimitsRequest):
            return {
                ...state,
                getLimitsRequestStatus: RequestState.request
            };
        case getType(sellerAdminLimitActions.getTradeLimitsSuccess):{
            const tradingLimits = { ...state.tradingLimits };
            const newLimits = action.payload.limits;
            newLimits.forEach((limit: { currency: string, type: TradingLimitType, size: number }) => {
                if(tradingLimits[limit.type]?.[limit.currency as keyof typeof Currency] !== undefined) {
                    tradingLimits[limit.type][limit.currency as keyof typeof Currency] = limit.size
                }
            });
            return {
                ...state,
                tradingLimits,
                getLimitsRequestStatus: RequestState.success
            };
        }
        case getType(sellerAdminLimitActions.updateTradeLimitsRequest):
            return {
                ...state,
                updateLimitsRequestStatus: RequestState.request
            };
        case getType(sellerAdminLimitActions.updateTradeLimitsSuccess):
            return {
                ...state,
                updateLimitsRequestStatus: RequestState.success
            };
        case getType(sellerAdminLimitActions.updateTradeLimitsFailure):
            return {
                ...state,
                updateLimitsRequestStatus: RequestState.failure
            };
        case getType(sellerAdminLimitActions.reset):
            return {
                ...initialState
            };
        default:
            return state;
    }
};
