import { rulesActions } from '../constants';

const initialState = {
    show: false,
    positionId: undefined
};

export function rules(state = initialState, action) {
    switch (action.type) {
        case rulesActions.RULES_SHOW:
            return { show: true, positionId: action.positionId, separatePanel: action.separatePanel };
        case rulesActions.RULES_HIDE:
            return { show: false };
        default:
            return state;
    }
}
