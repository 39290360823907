import { AnyAction } from "redux";
import { UserProfilePhotoState } from '../types/state/UserProfilePhotoState';
import { userProfilePhotoActionTypes } from '../constants/actionTypes/user-profile-photo-action';

const initialState: UserProfilePhotoState = {
    userPhotos: {},
    userPhotoRequestState: {},
    thumbnails: {},
    thumbnailsRequestState: {}
};

export function userProfilePhoto(state = initialState, action: AnyAction): UserProfilePhotoState {
    switch (action.type) {
        case userProfilePhotoActionTypes.USER_PROFILE_PHOTO_SET_USER_PHOTO:
            return {
                ...state,
                userPhotos: {
                    ...state.userPhotos,
                    [action.payload.userId]: action.payload.imageBlobUrl
                }
            };
        case userProfilePhotoActionTypes.USER_PROFILE_PHOTO_SET_FETCH_PHOTO_REQUEST_STATE:
            return {
                ...state,
                userPhotoRequestState: {
                    ...state.userPhotoRequestState,
                    [action.payload.userId]: action.payload.requestState
                }
            };
        case userProfilePhotoActionTypes.STORE_PHOTO_THUMBNAIL:
            return {
                ...state,
                thumbnails: {
                    ...state.thumbnails,
                    [action.payload.userId]: action.payload.imageBlobUrl
                }
            };
        case userProfilePhotoActionTypes.PHOTO_THUMBNAIL_REQUEST_STATE: {
            return {
                ...state,
                thumbnailsRequestState: {
                    ...state.thumbnailsRequestState,
                    [action.payload.userId]: action.payload.requestState
                }
            };
        }
        case userProfilePhotoActionTypes.USER_PROFILE_PHOTO_RESET:
            return { ...initialState };
        default:
            return state;
    }
}
