import { getType, ActionType } from "typesafe-actions";
import { tableActions } from "../../actions/table.actions";
import { TableState } from "../../types/state/ui/TableState";

export const tableInitialState: TableState = {
    hover: {},
    expand: {},
    sort: {}
}

export const tableReducer = (
    state = tableInitialState,
    action: ActionType<typeof tableActions[keyof typeof tableActions]>): TableState => {
    switch (action.type) {
        case getType(tableActions.expandedChange):
            return {
                ...state,
                expand: {
                    ...state.expand,
                    [action.payload.rowKey]: action.payload.expanded
                }
            };

        case getType(tableActions.hoverChange):
            return {
                ...state,
                hover: {
                    [action.payload.rowKey]: action.payload.hover
                }
            };
        case getType(tableActions.resetExpanded):
            return {
                ...state,
                expand: {}
            };
        case getType(tableActions.sortByChange):
            return {
                ...state,
                sort: {
                    ...state.sort,
                    [action.payload.tableKey]: {
                        sortBy: action.payload.sortBy,
                        sortDirection: action.payload.sortDirection,
                        sortingType: action.payload.sortingType
                    }
                }
            };
        case getType(tableActions.reset):
            return { ...tableInitialState };
        default: return state;
    }
}
