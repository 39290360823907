import { AnyAction } from "redux"
import { getType } from "typesafe-actions";
import { salesRepresentativeActions } from "../actions";
import { SalesRepresentativeState } from "../types/state/SalesRepresentativeState"

const initialState: SalesRepresentativeState = {
    salesRepresentatives: []
}

export const salesRepresentative = (state = initialState, action: AnyAction): SalesRepresentativeState => {
    switch (action.type) {
        case getType(salesRepresentativeActions.storeSalesRepresentatives):
            return {
                ...state,
                salesRepresentatives: action.payload.salesRepresentatives
            };
        case getType(salesRepresentativeActions.reset):
            return {
                ...initialState
            };
        default: return state;
    }
}
