import { pxTalkListActions } from '../constants';

const initialState = {
    visible: false,
    positionId: undefined
};

export function pxTalkList(state = initialState, action) {
    switch (action.type) {
        case pxTalkListActions.PX_TALK_LIST_VISIBLE:
            return {
                visible: action.visible,
                positionId: action.positionId
            };
        default:
            return state;
    }
}
