import { manageParsedBwicsActions as actionTypes, changeColorActions, changePxTalkActions, bwicDateFilterOptions, bwicStatuses } from '../constants';

const pagingInitialState = {
    currentPageNumber: 1,
    pageSize: 50,
    totalRecordNumber: 0
};

const initialFilter = {
    statuses: bwicStatuses.toArray().map(x => ({
        text: x.title,
        value: x.key,
        selected: false
    })),
    stateOptions: [
        { value: true, text: 'Published', selected: false },
        { value: false, text: 'Unpublished', selected: false }
    ],
    selectedDateOption: bwicDateFilterOptions.unspecified,
    customDateRange: {}
}

const initialState = {
    bwics: [],
    isLoading: true,
    searchInProgress: false,
    isNextPageRequesting: false,
    filter: { ...initialFilter, selectedDateOption: bwicDateFilterOptions.todayAndUpcoming },
    initialFilter: initialFilter,
    publishDialog: {},
    unpublishDialog: {},
    deleteBwicDialog: {},
    collapseAll: false,
    lastAppliedFilter: { ...initialFilter },
    editPxTalk: null,
    editColor: null,
    requestStatusSetIsAmrFlag: {},
    ...pagingInitialState
};

export function manageParsedBwics(state = initialState, action) {
    switch (action.type) {
        case actionTypes.RESET:
            return action.hardReset
                ? initialState
                : {
                    ...initialState,
                    searchTerm: action.searchTerm,
                    initialFilter: initialFilter,
                    filter: state.filter,
                    lastAppliedFilter: state.lastAppliedFilter,
                };
        case actionTypes.SET_FILTER_APPLIED_STATE:
            return {
                ...state,
                filter: initialFilter,
                lastAppliedFilter: initialFilter,
                searchTerm: action.searchTerm
            };
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case actionTypes.RESET_FILTERS:
            return {
                ...state,
                filter: initialFilter
            };
        case actionTypes.REFRESH_LAST_UPDATED_DATE:
            return {
                ...state,
                refreshLastUpdatedDate: !state.refreshLastUpdatedDate
            };
        case actionTypes.SEARCH_PROGRESS:
            return {
                ...state,
                searchInProgress: true
            };
        case actionTypes.SEARCH_COMPLETE: {
            const currentBwicList = (action.isNextPageRequesting ? state.bwics : []) || [];
            const requestedBwics = action.bwics.map(b => ({ ...b, collapsed: !!state.collapseAll }));

            return {
                ...state,
                bwics: currentBwicList.concat(requestedBwics),
                searchInProgress: false,
                currentPageNumber: action.nextPageNumber,
                totalRecordNumber: action.totalRecordNumber,
                lastAppliedFilter: action.isNextPageRequesting ? state.lastAppliedFilter : { ...state.filter },
            };

        }
        case actionTypes.UPDATE_BWIC_LIST:
            return {
                ...state,
                bwics: action.payload.bwics
            };
        case actionTypes.FILTER_STATUSES_LOADED:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    statuses: action.statuses
                }
            };
        case actionTypes.FILTER_STATE_LOADED:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    stateOptions: action.stateOptions
                }
            };
        case actionTypes.FILTER_DATE_CHANGE: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    selectedDateOption: action.option,
                    customDateRange: action.option.key === bwicDateFilterOptions.custom.key ?
                        state.filter.customDateRange : {}
                },
            };
        }
        case actionTypes.FILTER_CUSTOM_DATE_CHANGE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    customDateRange: action.dateRange
                }
            };
        case actionTypes.FILTER_STATUS_SELECTION_CHANGE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    statuses: state.filter.statuses.map(s =>
                        action.statuses.some(selectedStatus => selectedStatus === s.value)
                            ? { ...s, selected: !s.selected } : s)
                }
            };
        case actionTypes.FILTER_PUBLISHED_STATE_SELECTION_CHANGE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    stateOptions: state.filter.stateOptions.map(s =>
                        s.value === action.published
                            ? { ...s, selected: !s.selected } : s)
                }
            };
        case actionTypes.SET_BWIC_COLLAPSED_STATE:
            return {
                ...state,
                bwics: state.bwics.map(b =>
                    b.referenceName === action.bwic.referenceName
                        ? { ...b, collapsed: action.bwic.collapsed }
                        : b)
            };
        case actionTypes.POSITION_SELECTION_CHANGE:
            return {
                ...state,
                bwics: state.bwics.map(b =>
                    b.referenceName === action.selection.bwicReferenceName
                        ? {
                            ...b,
                            positions: b.positions.map(p =>
                                p.id === action.selection.positionId
                                    ? { ...p, selected: action.selection.selected }
                                    : p)
                        }
                        : b
                )
            };
        case actionTypes.PUBLISH_DIALOG:
            return {
                ...state,
                publishDialog: action.dialog
            };
        case actionTypes.UNPUBLISH_DIALOG:
            return {
                ...state,
                unpublishDialog: action.dialog
            };
        case actionTypes.EDIT_PXTALK:
            return {
                ...state,
                editPxTalk: action.editPxTalk,
                priceTalkLock: action.priceTalkLock
            };
        case actionTypes.UPDATE_PXTALK: {
            return {
                ...state,
                editPxTalk: null,
                bwics: state.bwics.map(b =>
                    b.referenceName === action.update.bwicReferenceName
                        ? {
                            ...b,
                            positions: b.positions.map(p =>
                                p.id === action.update.positionId
                                    ? { ...p, pxTalks: action.update.pxTalks, priceTalkLock: action.update.priceTalkLock }
                                    : p)
                        }
                        : b)
            };
        }
        case actionTypes.EDIT_COLOR:
            return {
                ...state,
                editColor: action.editColor
            };
        case actionTypes.UPDATE_COLOR:
            return {
                ...state,
                editColor: null,
                bwics: state.bwics.map(b =>
                    b.referenceName === action.update.bwicReferenceName
                        ? {
                            ...b,
                            positions: b.positions.map(p =>
                                p.id === action.update.positionId
                                    ? { ...p, color: action.update.color }
                                    : p)
                        }
                        : b)
            };
        case actionTypes.DELETE_BWIC_DIALOG:
            return {
                ...state,
                deleteBwicDialog: action.dialog
            };
        case actionTypes.SET_COLLAPSE_ALL:
            return {
                ...state,
                collapseAll: action.collapseAll,
                bwics: state.bwics.map(b => b.collapsed === action.collapseAll
                    ? b
                    : { ...b, collapsed: action.collapseAll })
            };
        case changeColorActions.RESET:
            return {
                ...state,
                editColor: null
            };
        case changePxTalkActions.RESET:
            return {
                ...state,
                editPxTalk: null
            };
        case actionTypes.MANAGE_PARSED_BWICS_NEXT_PAGE_REQUESTING:
            return {
                ...state,
                isNextPageRequesting: action.isRequesting
            };
        case actionTypes.MANAGE_PARSED_BWICS_RESET_PAGING:
            return {
                ...state,
                ...pagingInitialState
            };
        case actionTypes.MANAGE_PARSED_BWICS_SET_REQUEST_STATUS_IS_AMR:
            return {
                ...state,
                requestStatusSetIsAmrFlag: {
                    ...state.requestStatusSetIsAmrFlag,
                    [action.payload.referenceName]: action.payload.isRequesting
                }
            };
        case actionTypes.MANAGE_PARSED_BWICS_SET_IS_AMR_FLAG:
            return {
                ...state,
                bwics: state.bwics.map(b => (b.referenceName === action.payload.referenceName ? { ...b, isAMR: action.payload.status } : b))
            };
        default:
            return state;
    }
}
