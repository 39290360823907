import { ActionType, getType } from 'typesafe-actions';
import { userRequestAdminActions } from '../actions/user-request-admin.actions';
import { ManageCompaniesState } from '../types/state/ManageCompaniesState';
import { RequestState } from '../constants/request-state';

type TAction =
    ActionType<typeof userRequestAdminActions.setOperationState |
        typeof userRequestAdminActions.userRequestApproved |
        typeof userRequestAdminActions.userRequestBlocked |
        typeof userRequestAdminActions.userRequestUnblocked |
        typeof userRequestAdminActions.setPendingUserRequestCount |
        typeof userRequestAdminActions.userRequestDeleted |
        typeof userRequestAdminActions.getPendingUserRequestCount>;


export const userRequestAdminReducer = (state: ManageCompaniesState, action: TAction) => {
    switch (action.type) {
        case getType(userRequestAdminActions.setOperationState):
            return {
                ...state,
                userRequestOperationState: {
                    ...state.userRequestOperationState,
                    [action.payload.userRequestId]: action.payload.requestState
                }
            };
        case getType(userRequestAdminActions.getPendingUserRequestCount):
            return {
                ...state,
                requestStateGetPendingUserRequestCount: RequestState.request
            }
        case getType(userRequestAdminActions.setPendingUserRequestCount):
            return {
                ...state,
                pendingUserRequestCount: action.payload.count,
                requestStateGetPendingUserRequestCount: action.payload.requestState
            }
        case getType(userRequestAdminActions.userRequestApproved):
            return {
                ...state,
                users: state.users.concat(action.payload.user),
                userRequests: state.userRequests.filter(r => r.id !== action.payload.userRequestId),
                pendingUserRequestCount: state.pendingUserRequestCount > 0 ? state.pendingUserRequestCount - 1 : 0
            };
        case getType(userRequestAdminActions.userRequestBlocked):
            return {
                ...state,
                userRequests: state.userRequests.map(r => r.id === action.payload.userRequest.id
                    ? action.payload.userRequest
                    : r
                ),
                pendingUserRequestCount: state.pendingUserRequestCount > 0 ? state.pendingUserRequestCount - 1 : 0
            };
        case getType(userRequestAdminActions.userRequestUnblocked):
            return {
                ...state,
                userRequests: state.userRequests.map(r => r.id === action.payload.userRequest.id
                    ? action.payload.userRequest
                    : r
                )
            };
        case getType(userRequestAdminActions.userRequestDeleted):
            return {
                ...state,
                userRequests: state.userRequests.filter(r => r.id !== action.payload.userRequestId)
            };
        default:
            return state;
    }
};
