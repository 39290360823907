import { portfolioAgreementDisclaimerActions } from '../actions/portfolio-agreement-disclaimer.actions';
import { RequestState } from '../constants/request-state';
import { ActionType } from 'typesafe-actions';
import { getType } from 'typesafe-actions';
import { PortfolioAgreementDisclaimerState } from '../types/state/PortfolioAgreementDisclaimerState';

const initialState: PortfolioAgreementDisclaimerState = {
    confirmRequestState: RequestState.none,
    downloadFileRequestState: RequestState.none,
    visible: false,
    confirmed: undefined,
    blockerId: '',
    agreeButtonText: 'Next'
};

export const portfolioAgreementDisclaimer = (
    state = initialState,
    action: ActionType<typeof portfolioAgreementDisclaimerActions[keyof typeof portfolioAgreementDisclaimerActions]>
): PortfolioAgreementDisclaimerState => {
    switch (action.type) {
        case getType(portfolioAgreementDisclaimerActions.show):
            return {
                ...state,
                blockerId: action.payload.blockerId,
                confirmed: false,
                visible: true,
                agreeButtonText: action.payload.text ?? initialState.agreeButtonText
            };
        case getType(portfolioAgreementDisclaimerActions.hide):
            return {
                ...state,
                confirmed: action.payload.confirmed,
                visible: false
            };
        case getType(portfolioAgreementDisclaimerActions.confirmRequest):
            return {
                ...state,
                confirmRequestState: RequestState.request
            };
        case getType(portfolioAgreementDisclaimerActions.confirmSuccess):
            return {
                ...state,
                confirmRequestState: RequestState.success
            };
        case getType(portfolioAgreementDisclaimerActions.confirmFailure):
            return {
                ...state,
                confirmRequestState: RequestState.failure
            };
        case getType(portfolioAgreementDisclaimerActions.downloadFileRequest):
            return {
                ...state,
                downloadFileRequestState: RequestState.request
            };
        case getType(portfolioAgreementDisclaimerActions.downloadFileSuccess):
            return {
                ...state,
                downloadFileRequestState: RequestState.success
            };
        case getType(portfolioAgreementDisclaimerActions.downloadFileFailure):
            return {
                ...state,
                downloadFileRequestState: RequestState.failure
            };
        case getType(portfolioAgreementDisclaimerActions.reset):
            return {
                ...initialState
            };
        default:
            return state;
    }
};
