import { ActionType, getType } from 'typesafe-actions';
import { RequestState } from '../constants/request-state';
import { securityDetailsActions } from '../actions';
import { SecurityDetailsState } from '../types/state/SecurityDetailsState';

const initialState: SecurityDetailsState = {
    security: null,
    initRequestState: RequestState.none,
    agencyRatings: undefined,
    ratingMappings: []
};

export const securityDetails = (
    state = initialState,
    action: ActionType<typeof securityDetailsActions[keyof typeof securityDetailsActions]>): SecurityDetailsState => {
    switch (action.type) {
        case getType(securityDetailsActions.securityDetailsInitRequest):
            return {
                ...initialState,
                initRequestState: RequestState.request,
                security: action.payload.security
            };
        case getType(securityDetailsActions.securityDetailsInitSuccess):
            return {
                ...state,
                initRequestState: RequestState.success,
                agencyRatings: action.payload.ratings,
                ratingMappings: action.payload.mappings
            };
        case getType(securityDetailsActions.securityDetailsInitFailure):
            return {
                ...state,
                initRequestState: RequestState.failure
            };
        case getType(securityDetailsActions.securityDetailsReset):
            return { ...initialState };
        default:
            return state;
    }
};
