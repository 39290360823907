import { combineReducers } from 'redux';
import { roles } from '../constants';
import { error } from './error.reducer';
import { userProfilePhoto } from './user-profile-photo.reducer';
import { authentication } from './authentication.reducer';
import { blotter } from './blotter.reducer';
import { profile } from './profile.reducer';
import { securities } from './securities.reducer';
import { newBWIC } from './newBWIC.reducer';
import { process } from './process.reducer';
import { uploadDocuments } from './upload.documents.reducer';
import { submit } from './submit.reducer';
import { allBWICS } from './all.bwics.reducer';
import { companies } from './companies.reducer';
import { notification } from './notification.reducer';
import { portfolio } from './portfolio.reducer';
import { editPortfolio } from './edit.portfolio.reducer';
import { rules } from './rules.reducer'
import { manageParsedBwics } from './manage.parsed.bwics.reducer';
import { changePxTalk } from './change.pxTalk.reducer';
import { forms } from './forms.reducer';
import { changeColor } from './change.color.reducer';
import { editParsedBwic } from './edit.parsed.bwic.reducer';
import { grid } from './grid.reducer';
import { contactsSave } from './contacts.save.reducer';
import { editCompany } from './edit.company.reducer';
import { searchSecurities } from './search.securities.reducer';
import { bidding } from './bidding.reducer';
import { sellerBidding } from './seller.bidding.reducer';
import { otherSellerBidding } from './otherSeller.bidding.reducer';
import { brokerDealerBidding } from './brokerDealer.bidding.reducer';
import { dealers } from './dealers.reducer';
import { companyMembers } from './company-members.reducer';
import { tradeConfirm } from './trade.confirm.reducer';
import { bidOnBehalf } from './bid-on-behalf.reducer';
import { trades } from './trades.reducer';
import { onBoardingTooltip } from "./onBoardingTooltip.reducer";
import { dashboardReducer as dashboard } from './dashboard.reducer';
import { inviteToJoin } from './inviteToJoin.reducer';
import { securityDetails } from './security-details.reducer';
import { bwicHistory } from './bwic-history.reducer';
import { pxTalkList } from './pxTalk-list.reducer';
import { verifyIdentity } from './verify.identity.reducer';
import { clientsCompanies } from './clients-companies.reducer';
import { sellerBuyside } from './seller-buyside.reducer';
import { imDetailsDeals } from './im-details-deals.reducer';
//import { subscriptions } from './subscriptions.reducer';
//import { upgradeSubscription } from './upgrade-subscription.reducer';
import { contactSales } from './contact-sales.reducer';
import { salesRepresentative } from './sales-representative.reducer';
import { dealerListPanel } from './dealer-list-panel.reducer';
import { favoriteBrokerDealers } from './favorite-broker-dealers.reducer';
import { apiOperation } from './api-operation.reducer';
import { entities } from './entities/entities.reducer';
import { filterActionsReducer } from './filter-actions.reducer';
import { bdInventory } from './bd-inventory.reducer';
import { inventory } from './inventory.reducer';
import { inventoryEdit } from './inventory-edit.reducer';
import { blastMessage } from './blast-message.reducer';
import { companyDetails } from './company-details.reducer';
import { manageEmailPreferences } from './manage-email-preferences.reducer';
import { dealerList } from './dealer-list.reducer';
import { addToPortfolio } from './add-to-portfolio.reducer';
import { release } from './release.reducer';
import { sellerAdminLimit } from './seller-admin-limit.reducer';
import { newBwicDealersReducer } from './new-bwic-dealers.reducer';
import { directBiddingDisclaimer } from './direct-bidding-agreement-disclaimer.reducer';
import { dealersStats } from './dealers-stats.reducer';
import { sellerAdminUsers } from './seller-admin-users.reducer';
import { sellerAdminDealers } from './seller-admin-dealers.reducer';
import { imUserConfig } from './im-user-config.reducer';
import { signup } from './signup.reducer';
import { ui } from './ui/ui.reducer';
import { textRulesPanel } from './text-rules-panel.reducer';
import { helpResources } from './help-resources.reducer';
import { manageEmailReducer } from './manage-email.reducer';
import { manageTokenReducer } from './manage-token.reducer';
import { issuanceMonitor } from './issuance-monitor.reducer';
import { cloManagers } from './clo-managers.reducer';
import { address } from './address.reducer';
import { changeSubscription } from './change-subscription.reducer';
import { portfolioAgreementDisclaimer } from './portfolio-agreement-disclaimer.reducer';
import { pushHistoryReducer } from './push-history.reducer';
import { filters } from "./filters.reducer";
import { news } from './news.reducer';
import { editNews } from './edit-news.reducer';
import { bwicLogReducer } from './bwic-log.reducer';
import { banks } from './banks.reducer';
import { deals } from './deals.reducer';

const rootReducer = () => combineReducers({
    error,
    forms,
    filters,
    grid: filterActionsReducer(grid, [], action => action.type.indexOf('grid/') === 0),
    companyMembers,
    changePxTalk,
    changeColor,
    searchSecurities,
    notification: filterActionsReducer(notification, null, action => action.type.indexOf('notification/') === 0),
    authentication,
    profile,
    securities,
    process,
    uploadDocuments,
    newBWIC: filterActionsReducer(newBWIC, [...roles.seller(), ...roles.bd()]),
    submit,
    allBWICS,
    companies: filterActionsReducer(companies, [roles.Administrator, roles.SubscriptionManager]),
    portfolio,
    editPortfolio,
    rules,
    manageParsedBwics: filterActionsReducer(manageParsedBwics, [roles.Administrator, roles.SubscriptionManager, roles.DataEntry]),
    editParsedBwic: filterActionsReducer(editParsedBwic, [roles.Administrator, roles.SubscriptionManager, roles.DataEntry]),
    contactsSave,
    editCompany: filterActionsReducer(editCompany, [roles.Administrator, roles.SubscriptionManager]),
    bidding,
    sellerBidding: filterActionsReducer(sellerBidding, [...roles.seller(), ...roles.bd()]),
    otherSellerBidding: filterActionsReducer(otherSellerBidding, roles.seller()),
    brokerDealerBidding: filterActionsReducer(brokerDealerBidding, roles.bd()),
    tradeConfirm: filterActionsReducer(tradeConfirm, [...roles.bd(), ...roles.seller()], action => action.type.indexOf('tradeConfirm/') === 0),
    bidOnBehalf: filterActionsReducer(bidOnBehalf, roles.seller()),
    trades: filterActionsReducer(trades, [roles.Administrator, roles.DataEntry]),
    onBoardingTooltip,
    dashboard: filterActionsReducer(dashboard, [], action => action.type.indexOf('dashboard/') === 0),
    inviteToJoin,
    securityDetails,
    bwicHistory,
    pxTalkList,
    dealers: filterActionsReducer(dealers, roles.seller()),
    verifyIdentity,
    clientsCompanies: filterActionsReducer(
        clientsCompanies,
        roles.bd(),
        action => (
            action.type.indexOf('clientCompanies/') === 0 ||
            action.type.indexOf('push/settlement-agent-agreement/') === 0
        )),
    sellerBuyside: filterActionsReducer(
        sellerBuyside,
        roles.seller(),
        action => (
            action.type.indexOf('sellerBuyside/') === 0 ||
            action.type.indexOf('push/settlement-agent-agreement/') === 0 ||
            action.type.indexOf('push/bwic/') === 0
        )),
    issuanceMonitor,
    imDetailsDeals,
    //subscriptions,
    changeSubscription: filterActionsReducer(
        changeSubscription,
        [roles.Administrator, roles.SubscriptionManager],
        action => action.type.indexOf('changeSubscriptionActions/') === 0),
    //upgradeSubscription,
    contactSales,
    salesRepresentative,
    userProfilePhoto,
    dealerListPanel: filterActionsReducer(dealerListPanel, [...roles.seller(), ...roles.bd()], action => action.type.indexOf('dealerListPanel/') === 0),
    apiOperation,
    favoriteBrokerDealers: filterActionsReducer(favoriteBrokerDealers, roles.seller(), action => action.type.indexOf('favoriteBd/') === 0),
    blotter,
    entities,
    ui,
    bdInventory: filterActionsReducer(bdInventory, roles.bd(), action => action.type.indexOf('bd-inventory/') === 0),
    inventory: filterActionsReducer(inventory, [...roles.seller(), roles.Administrator, roles.DataEntry, roles.Viewer], action => action.type.indexOf('inventory/') === 0),
    inventoryEdit: filterActionsReducer(inventoryEdit, [roles.BrokerDealerTrader, roles.Administrator, roles.DataEntry], action => action.type.indexOf('inventoryEdit/') === 0),
    blastMessage: filterActionsReducer(blastMessage, [roles.SellerTrader, roles.BrokerDealerTrader], action => action.type.indexOf('blastMessage/') === 0),
    companyDetails,
    dealerList: filterActionsReducer(dealerList, null, action => action.type.indexOf('dealer-list/') === 0),
    addToPortfolio: filterActionsReducer(addToPortfolio, null, action => action.type.indexOf('addToPortfolio/') === 0),
    release: filterActionsReducer(release, null, action => action.type.indexOf('release/') === 0),
    newBwicDealers: filterActionsReducer(newBwicDealersReducer, null, action => (
        action.type.indexOf('newBwicDealers/') === 0 ||
        action.type.indexOf('dealerListFilter/') === 0
    )),
    manageEmailPreferences: filterActionsReducer(manageEmailPreferences, null, action => action.type.indexOf('manageEmailPreferences/') === 0),
    directBiddingDisclaimer: filterActionsReducer(directBiddingDisclaimer, [roles.SellerTrader, roles.BrokerDealerTrader], action => action.type.indexOf('directBiddingDisclaimer/') === 0),
    portfolioAgreementDisclaimer: filterActionsReducer(portfolioAgreementDisclaimer, null, action => action.type.indexOf('portfolioAgreementDisclaimer/') === 0),
    sellerAdminLimit: filterActionsReducer(sellerAdminLimit, [roles.SellerAdministrator, roles.SellerTrader, roles.SellerViewer], action => action.type.indexOf('sellerAdminLimit/') === 0),
    sellerAdminDealers: filterActionsReducer(sellerAdminDealers, [roles.SellerAdministrator], action => action.type.indexOf('sellerAdminDealers/') === 0),
    sellerAdminUsers: filterActionsReducer(sellerAdminUsers, [roles.SellerAdministrator, roles.SellerTrader, roles.SellerViewer], action => action.type.indexOf('sellerAdminUsers/') === 0),
    dealersStats: filterActionsReducer(dealersStats, [...roles.seller(), roles.BrokerDealerTrader], action => action.type.indexOf('dealersStats/') === 0),
    imUserConfig: filterActionsReducer(imUserConfig, null, action => action.type.indexOf('userconfig/') === 0),
    signup: filterActionsReducer(signup, null, action => action.type.indexOf('signup/') === 0),
    textRulesPanel: filterActionsReducer(textRulesPanel, null, action => action.type.indexOf('textRulesPanel/') === 0),
    manageEmail: filterActionsReducer(manageEmailReducer, [roles.Administrator, roles.SubscriptionManager, roles.DataEntry], action => action.type.indexOf('manageEmail/') === 0),
    manageToken: filterActionsReducer(manageTokenReducer, [roles.Administrator], action => action.type.indexOf('manageToken/') === 0),
    helpResources: filterActionsReducer(helpResources, null, action => action.type.indexOf('help-resources/') === 0),
    cloManagers: filterActionsReducer(cloManagers, roles.cloManagersAccess()),
    banks: filterActionsReducer(banks, roles.banksAccess()),
    address,
    pushHistory: filterActionsReducer(pushHistoryReducer, null, action => action.type.indexOf('push-history/') === 0),
    news: filterActionsReducer(news, [...roles.bd(), ...roles.seller(), roles.Administrator, roles.DataEntry]),
    editNews: filterActionsReducer(editNews, [roles.Administrator, roles.DataEntry]),
    bwicLog: filterActionsReducer(bwicLogReducer, null, action => action.type.indexOf('bwic-log/') === 0),
    deals,
});

export default rootReducer;
