import { SellerAdminUsersState } from '../types/state/SellerAdminUsersState';
import { ActionType } from '../types/ActionType';
import { getType } from 'typesafe-actions';
import { sellerAdminUsersActions } from '../actions/seller-admin-users.actions';
import { RequestState } from '../constants/request-state';
import { UserStatus } from '../types/account/UserStatus';

const initialState: SellerAdminUsersState = {
    users: [],
    requestUsers: [],
    requestStateGetUserList: RequestState.none,
    requestStatusUpdateRequestUser: RequestState.none,
    requestStatusUpdateUser: RequestState.none,
    userRequestOperationState: {},
    isRequestingExportUser: false,
    userOperationState: {},
    changeBwicAnnouncementNotificationsRequestState: RequestState.none,
};

export const sellerAdminUsers = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case getType(sellerAdminUsersActions.getUserListRequest):
            return {
                ...state,
                requestStateGetUserList: RequestState.request
            };
        case getType(sellerAdminUsersActions.getUserListSuccess):
            return {
                ...state,
                users: action.payload.users,
                requestUsers: action.payload.requestUsers,
                requestStateGetUserList: RequestState.success,
                requestStatusUpdateRequestUser: RequestState.none,
                requestStatusUpdateUser: RequestState.none,
            };
        case getType(sellerAdminUsersActions.getUserListFailure):
            return {
                ...state,
                requestStateGetUserList: RequestState.failure
            };
        case getType(sellerAdminUsersActions.setUserRequestUpdateState):
            return {
                ...state,
                requestStatusUpdateRequestUser: action.payload.requestState,
            };
        case getType(sellerAdminUsersActions.setUserUpdateState):
            return {
                ...state,
                requestStatusUpdateUser: action.payload.requestState
            };
        case getType(sellerAdminUsersActions.setUserRequestOperationState):
            return {
                ...state,
                userRequestOperationState: {
                    ...state.userRequestOperationState,
                    [action.payload.userId]: action.payload.requestState
                }
            };

        case getType(sellerAdminUsersActions.userRequestEnabled):
        case getType(sellerAdminUsersActions.userRequestDisabled):
            return {
                ...state,
                requestUsers: state.requestUsers.map(u => u.id === action.payload.userRequest.id ? action.payload.userRequest : u),
            };
        case getType(sellerAdminUsersActions.userRequestSaved):
            return {
                ...state,
                requestUsers: state.requestUsers.map(u => u.id === action.payload.userRequest.id ? action.payload.userRequest : u),
                requestStateGetUserList: RequestState.none,
            };
        case getType(sellerAdminUsersActions.setUserEnabled):
            return {
                ...state,
                users: state.users.map(u => u.id === action.payload.userId ? { ...u, status: action.payload.status } : u),
            };
        case getType(sellerAdminUsersActions.setUserDisable):
            return {
                ...state,
                users: state.users.map(u => u.id === action.payload.userId ? { ...u, status: UserStatus.Blocked, blockReason: action.payload.blockReason } : u),
            };
        case getType(sellerAdminUsersActions.userSaved):
            return {
                ...state,
                users: state.users.map(u => u.id === action.payload.user.id ? action.payload.user : u),
                requestStateGetUserList: RequestState.none,
            };
        case getType(sellerAdminUsersActions.setUserOperationState):
            return {
                ...state,
                userOperationState: {
                    ...state.userOperationState,
                    [action.payload.userId]: action.payload.requestState
                }
            };
        case getType(sellerAdminUsersActions.exportUsersRequest):
            return {
                ...state,
                isRequestingExportUser: true
            };
        case getType(sellerAdminUsersActions.exportUsersResponse):
            return {
                ...state,
                isRequestingExportUser: false
            };
        case getType(sellerAdminUsersActions.changeBwicAnnouncementNotificationsRequest):
            return {
                ...state,
                changeBwicAnnouncementNotificationsRequestState: action.payload.requestState
            };
        case getType(sellerAdminUsersActions.reset):
            return {
                ...initialState
            };
        default:
            return state;
    }
};
