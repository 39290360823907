import { uploadDocumentsActions as actionTypes } from '../constants';

const initialState = {
    isInitialized: false,
    isUploading: false,
    deletingDocuments: {},
    deals: [],
    uploadingDocuments: [],
    uploadAll: { visible: false },
    upload: { visible: false },
};

export const uploadDocuments = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DEALS:
            return {
                ...state,
                deals: action.deals,
                isInitialized: true
            };
        case actionTypes.RESET:
            return initialState;
        case actionTypes.UPLOAD_DIALOG:
            return {
                ...state,
                upload: action.upload
            };
        case actionTypes.UPLOAD_ALL_DIALOG:
            return {
                ...state,
                uploadAll: action.uploadAll
            };
        case actionTypes.REFRESH_TIME:
            return {
                ...state,
                refreshFlag: !state.refreshFlag
            };
        case actionTypes.BEGIN_DOCUMENT_UPLOAD:
            return {
                ...state,
                uploadingDocuments: [...state.uploadingDocuments, action.uploadingDocument],
                isUploading: true
            };
        case actionTypes.PROGRESS_DOCUMENT_UPLOAD:
            return {
                ...state,
                uploadingDocuments: state.uploadingDocuments.map(d =>
                    d.uid === action.upload.uid ? { ...d, progress: action.upload.progress } : d)
            };
        case actionTypes.COMPLETE_DOCUMENT_UPLOAD: {
            const uploadingDocuments = state.uploadingDocuments.filter(d => d.uid !== action.uid);

            return {
                ...state,
                uploadingDocuments,
                isUploading: uploadingDocuments.length > 0
            };
        }
        case actionTypes.SET_REQUEST_STATUS_DELETING_DOCUMENT:
            const deletingDocuments = {...state.deletingDocuments};
            if (!deletingDocuments[action.payload.documentId]) {
                deletingDocuments[action.payload.documentId] = true;
            } else {
                delete deletingDocuments[action.payload.documentId];
            }
            return { ...state, deletingDocuments };
        default:
            return state;
    }
};
