import { keys } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';
import { pushHistoryActions } from '../actions/entities/push-history.actions';
import { PushHistoryState, PushHistoryStateItem, PushHistoryStateItemType } from '../types/state/PushHistoryState';

const pushDataInitialState: PushHistoryState = {
    history: {},
}

type TPushHistoryActions = ActionType<typeof pushHistoryActions[keyof typeof pushHistoryActions]>;

export const pushHistoryReducer = (state = pushDataInitialState, action: TPushHistoryActions): PushHistoryState => {
    switch (action.type) {
        case getType(pushHistoryActions.add): {
            const strategy = getStoreStrategy(action.payload.type);
            const items = state.history[action.payload.type] || [];

            return {
                ...state,
                history: {
                    ...state.history,
                    [action.payload.type]: strategy([...items], action.payload.item)
                }
            }
        }
        case getType(pushHistoryActions.resetForType): {
            const history = { ...state.history };
            keys(state.history).forEach(key =>
                action.payload.types.some(t => t === key) && delete history[key as PushHistoryStateItemType]
            )
            return { ...state, history }
        }
        case getType(pushHistoryActions.reset):
            return pushDataInitialState;
        default:
            return state;
    }
}

type TStrategy = (state: PushHistoryStateItem[], newItem: PushHistoryStateItem) => PushHistoryStateItem[]

function getStoreStrategy(type: PushHistoryStateItemType): TStrategy {
    switch (type) {
        case PushHistoryStateItemType.BwicStatusChanged:
        case PushHistoryStateItemType.BwicStage1Ended:
        case PushHistoryStateItemType.BwicStage2Ended:
            return latestByBwicStrategy;
        default:
            return latestPositionItemStrategy;
    }
}

function latestPositionItemStrategy(state: PushHistoryStateItem[], newItem: PushHistoryStateItem) {
    return state.filter(item => item.positionId !== newItem.positionId).concat(newItem);
}

function latestByBwicStrategy(state: PushHistoryStateItem[], newItem: PushHistoryStateItem) {
    return state.filter(item => item.bwicReferenceName !== newItem.bwicReferenceName).concat(newItem);
}
